import { Box } from '@mui/material';
import React from 'react';

export const Animation = ({ url, caption, captionColor = '#1B1A57' }) => {
  return (
    <Box paddingTop="10px">
      <img
        src={url}
        alt="preview"
        width="100%"
        height="100%"
        loading="lazy"
        srcSet={url}
      />
      {caption && (
        <Box
          sx={{
            fontSize: '12px',
            color: captionColor,
          }}
        >
          {caption}
        </Box>
      )}
    </Box>
  );
};
