import { Grid, Typography } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

import SettingsIcon from '@mui/icons-material/Settings';

import React from 'react';
import { appRoutes } from '../../routes/appRoutes';

export const QuickMessageMenuMenuItem = ({ onClick }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      sx={{
        width: 90,
        cursor: 'pointer',
      }}
      onClick={() => {
        navigate(appRoutes.QUICK_MESSAGES);
        onClick?.();
      }}
    >
      <SettingsIcon
        sx={{
          '& path': {
            fill: location.pathname === appRoutes.QUICK_MESSAGES ? '#2F80ED' : '#4F5E7B',
          },
        }}
      />
      <Typography
        sx={{
          color: location.pathname === appRoutes.QUICK_MESSAGES ? '#2F80ED' : '#4F5E7B',
          fontSize: 12,
        }}
      >
        设置快速回复
      </Typography>
    </Grid>
  );
};
