import { format } from 'date-fns';

export const convertTimeToLabel = (time) => {
  if (!time) {
    return '';
  }

  const convertedTime = format(new Date(time * 1000), 'yyyy.MM.dd kk:mm');

  return `${convertedTime}分`;
};
