import { Events } from 'web-socket';
import { updateChats } from 'web-socket/utils/update-chats';

export const handleMessageRead = ({
  parsedData,
  updateCachedData,
  updateChatCachedData,
}) => {
  if (parsedData?.type === Events.MessageRead) {
    const { chat_id, message_id } = parsedData;

    updateCachedData((draft) => {
      const chats = draft.data.list;

      const idxOfChat = chats.findIndex((chat) => chat?.opponent?.id === chat_id);

      if (idxOfChat !== -1) {
        // eslint-disable-next-line no-param-reassign
        draft.data.list = updateChats({
          chats,
          opponentId: chat_id,
          fieldsToUpdate: {
            last_read_message_id: message_id,
          },
        });
      }
    });

    updateChatCachedData((draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.data = {
        ...draft.data,
        last_read_message_id: message_id,
      };
    }, chat_id);
  }
};
