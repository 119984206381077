import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Avatar } from 'components/avatar/Avatar';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'routes/appRoutes';
import { isMobile } from 'components/app-layout/AppLayout';
import { UserProfilePopover } from 'components/user-profile-popover/UserProfilePopover';

export const IncomingMessageSkeleton = ({
  lastSeen,
  avatar,
  name,
  sentAt,
  editedAt,
  opponentId,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        padding: '0 16px',
        marginBottom: '16px',
      }}
    >
      <Avatar
        lastSeen={lastSeen}
        avatar={avatar}
        width={40}
        height={40}
        avatarWrapperSx={{
          height: 'auto',
          cursor: 'pointer',
          width: 40,
        }}
        onClick={(e) => {
          if (isMobile) {
            navigate(appRoutes.USER_PROFILE.replace(':id', opponentId));
          } else {
            setAnchorEl(e.currentTarget);
          }
        }}
      />
      <Box
        sx={{
          backgroundColor: '#F7F7F7',
          borderRadius: '8px',
          padding: '8px 10px',
          wordBreak: 'break-all',
          msWordBreak: 'break-all',
        }}
      >
        <Box
          sx={{
            fontSize: 12,
            color: '#F2994A',
          }}
        >
          {name}
        </Box>
        {children}
        <Box
          sx={{
            fontSize: 12,
            color: '#A1A1BC',
            paddingTop: '3px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {editedAt && (
            <Box
              sx={{
                marginRight: '5px',
                fontSize: 12,
                fontStyle: 'italic',
              }}
            >
              edited
            </Box>
          )}

          <Box>{sentAt}</Box>
        </Box>
      </Box>
      <UserProfilePopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        id={opponentId}
        nickname={name}
      />
    </Box>
  );
};
