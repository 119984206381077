import { useEffect } from 'react';

import {
  handleUserStatus,
  handleUserTyping,
  handleMessageReceivedChats,
  Events,
} from 'web-socket';
import {
  api,
  useGetChatsQuery,
  useLazyGetChatsQuery,
  useLazyGetMessagesQuery,
} from 'services/api';

import { useDispatch, useSelector } from 'react-redux';

import {
  getIMURL, getToken, setIMToken, setUser,
} from 'redux/auth/slice';
import { LSIMTokenName, LSUser } from 'pages/login/constants';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { appRoutes } from 'routes/appRoutes';
import { isMobile } from 'components/app-layout/AppLayout';
import { onSocketOpen } from './utils';
import { handleMessageRead } from './events/message-read';

const reconnect = ({
  token,
  socketURL,
  onMessage,
  getChatsData,
  getMessagesQuery,
  timeout = 0,
}) => {
  let formattedTimeout = timeout >= 10000 ? 10000 : timeout + 1000;

  setTimeout(() => {
    const formattedSocketURL = socketURL?.replace('https', 'wss');
    const socket = new WebSocket(formattedSocketURL);

    window.IMSocket = socket;

    socket.onopen = async () => {
      formattedTimeout = 0;
      onSocketOpen(socket, token)();

      await getChatsData(undefined);
      if (window.chatId) {
        await getMessagesQuery(window.chatId);
      }
    };

    socket.onclose = () => {
      reconnect({
        token,
        socketURL,
        onMessage,
        getChatsData,
        getMessagesQuery,
        timeout: formattedTimeout,
      });
    };

    socket.onmessage = onMessage;
  }, formattedTimeout);
};

const connect = ({
  token, socketURL, onMessage, getChatsData, getMessagesQuery,
}) => {
  const formattedSocketURL = socketURL?.replace('https', 'wss');
  const socket = new WebSocket(formattedSocketURL);

  window.IMSocket = socket;

  socket.onopen = onSocketOpen(socket, token);

  socket.onclose = () => {
    reconnect({
      token,
      socketURL,
      onMessage,
      getChatsData,
      getMessagesQuery,
    });
  };

  socket.onmessage = onMessage;
};

const messagePattern = (message) => message.startsWith('你好，我要充值');

const getAutoAnswers = async ({ dispatch }) => {
  const { data: autoAnswerData } = await dispatch(
    api.endpoints.getAutoAnswer.initiate(undefined, { forceRefetch: true }),
  );
  const { data: answersData } = await dispatch(
    api.endpoints.getAnswers.initiate(undefined, { forceRefetch: true }),
  );

  const autoAnswerId = autoAnswerData?.data;
  const autoAnswers = answersData?.data;
  const autoAnswer = autoAnswers.find(({ id }) => id === autoAnswerId);

  return autoAnswer?.text;
};

export const useEvents = () => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const socketURL = useSelector(getIMURL);
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const chatId = isMobile ? id : searchParams.get('chatId');

  const { data: chatsData, error } = useGetChatsQuery(undefined, {
    skip: !token,
  });
  const [getChatsData] = useLazyGetChatsQuery();
  const [getMessages] = useLazyGetMessagesQuery();

  const getMessagesQuery = async (chatIdValue) => {
    await getMessages({ chatId: chatIdValue, limit: 10, page: 1 });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (error && error.status === 401) {
      dispatch(api.util.resetApiState());
      localStorage.removeItem(LSUser);
      localStorage.removeItem(LSIMTokenName);
      dispatch(setIMToken(null));
      dispatch(setUser(null));

      if (window.IMSocket) {
        window.IMSocket.close();
      }

      navigate(appRoutes.LOGIN);
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    const onMessage = async (data) => {
      const parsedData = JSON.parse(data?.data);
      let autoAnswerMessage;
      let isNeedToAutoAnswer;

      if (parsedData?.type === Events.MessageReceived) {
        const { message } = parsedData;

        const autoAnswer = await getAutoAnswers({ dispatch });
        isNeedToAutoAnswer = message?.text && messagePattern(message.text) && autoAnswer;

        if (isNeedToAutoAnswer) {
          const { data: newMessageDataId } = await dispatch(
            api.endpoints.sendText.initiate({
              chat_id: Number(message?.chat_id),
              text: autoAnswer,
            }),
          );

          autoAnswerMessage = {
            id: newMessageDataId?.data,
            chat_id: Number(message?.chat_id),
            sender_id: message.user_id,
            sent_at: new Date().getTime() / 1000,
            text: autoAnswer,
          };
        }
      }

      const updateChatsCachedData = (updater) => dispatch(api.util.updateQueryData('getChats', undefined, updater));

      const updateChatCachedData = (updater, idOfChat) => {
        dispatch(api.util.updateQueryData('getChat', String(idOfChat), updater));
      };

      handleMessageReceivedChats({
        parsedData,
        chats: window.chatsData?.data?.list,
        updateCachedData: updateChatsCachedData,
        dispatch,
        isNeedToAutoAnswer,
        autoAnswerMessage,
      });

      handleUserStatus({
        updateCachedData: updateChatsCachedData,
        parsedData,
      });

      handleUserTyping({
        updateCachedData: updateChatsCachedData,
        updateChatCachedData,
        parsedData,
      });

      handleMessageRead({
        updateCachedData: updateChatsCachedData,
        updateChatCachedData,
        parsedData,
      });

      if (window.onDirectMessage) {
        window.onDirectMessage({ parsedData, isNeedToAutoAnswer, autoAnswerMessage });
      }
    };

    if (chatsData && !window.IMSocket) {
      connect({
        token,
        socketURL,
        onMessage,
        getChatsData,
        getMessagesQuery,
      });
    }
  }, [chatsData]);

  useEffect(() => {
    window.chatId = chatId;
  }, [chatId]);

  useEffect(() => {
    window.chatsData = chatsData;
  }, [chatsData]);
};
