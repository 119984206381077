import React from 'react';
import { Animation } from '../message-types/Animation';
import { File } from '../message-types/File';
import { ImageWithPreview } from '../message-types/ImageWithPreview';
import { Media } from '../message-types/Media';
import { TextMessage } from '../message-types/TextMessage';
import { VideoWithPreview } from '../message-types/VideoWithPreview';
import { IncomingMessageSkeleton } from './IncomingMessageSkeleton';

export const IncomingMessage = ({
  avatar,
  lastSeen,
  name,
  sentAt,
  scrollPosition,
  caption,
  opponentId,
  text = null,
  sticker = null,
  animation = null,
  media = null,
  photo = null,
  video = null,
  document = null,
  edited_at = '',
  ...rest
}) => {
  return (
    <IncomingMessageSkeleton
      avatar={avatar}
      sentAt={sentAt}
      name={name}
      lastSeen={lastSeen}
      editedAt={edited_at}
      opponentId={opponentId}
    >
      {(text || sticker) && <TextMessage text={text || sticker} {...rest} />}
      {photo && (
        <ImageWithPreview
          {...photo}
          scrollPosition={scrollPosition}
          caption={caption}
          {...rest}
        />
      )}
      {document && <File {...document} caption={caption} {...rest} />}
      {animation && <Animation {...animation} caption={caption} {...rest} />}
      {video && <VideoWithPreview {...video} caption={caption} {...rest} />}
      {media && <Media {...media} caption={caption} {...rest} />}
    </IncomingMessageSkeleton>
  );
};
