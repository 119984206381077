import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { LSIMTokenName } from 'pages/login/constants';
import { getIMURL } from 'redux/auth/slice';

import { handleMessageReceivedDirectMessage } from 'web-socket';
import { getQuickMessagesApi } from './getQuickMessagesApi';

const dynamicBaseQuery = async (args, api, extraOptions) => {
  const imURL = getIMURL(api.getState());
  const baseUrl = !imURL.includes('https') ? `https://${imURL}` : imURL;

  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, store) => {
      const { IMToken } = store.getState().auth;
      const token = localStorage.getItem(LSIMTokenName) || IMToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  });
  const { error, data } = await rawBaseQuery(args, api, extraOptions);

  if (error) {
    toast.error(
      error.data?.message || error?.message || error.status || 'unknown error',
      {
        duration: 15000,
      },
    );
    return { error: { status: error.status, data: error.data } };
  }

  return { data };
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: dynamicBaseQuery,
  keepUnusedDataFor: 1000000000,
  tagTypes: ['Chats', 'Messages'],
  endpoints: (builder) => ({
    getChats: builder.query({
      query: () => '/getChats?limit=200&page=1',
    }),
    getChat: builder.query({
      query: (id) => `/getChat?chat_id=${id}`,
    }),
    getMessages: builder.query({
      query: ({ chatId, limit, page }) => `/getMessages?chat_id=${chatId}&limit=${limit}&page=${page}`,
      async onCacheEntryAdded(
        data,
        {
          cacheDataLoaded, cacheEntryRemoved, updateCachedData, dispatch,
        },
      ) {
        try {
          await cacheDataLoaded;
          const chatId = data?.chatId;
          window.onDirectMessage = ({
            parsedData,
            isNeedToAutoAnswer,
            autoAnswerMessage,
          }) => {
            handleMessageReceivedDirectMessage({
              parsedData,
              updateCachedData,
              dispatch,
              chatId,
              isNeedToAutoAnswer,
              autoAnswerMessage,
            });
          };
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
        await cacheEntryRemoved;
      },
    }),
    getUser: builder.query({
      query: ({ id }) => `/getUser?id=${id}`,
    }),
    readMessage: builder.mutation({
      query: ({ chat_id, message_id }) => ({
        url: '/readMessage',
        method: 'POST',
        body: { chat_id, message_id },
      }),
    }),
    sendText: builder.mutation({
      query: ({ chat_id, text }) => ({
        url: '/sendText',
        method: 'POST',
        body: { chat_id, text },
      }),
    }),
    sendPhoto: builder.mutation({
      query: ({ chat_id, photo, caption = null }) => ({
        url: '/sendPhoto',
        method: 'POST',
        body: { chat_id, photo, ...(caption ? { caption } : {}) },
      }),
    }),
    sendVideo: builder.mutation({
      query: ({ chat_id, video, caption = null }) => ({
        url: '/sendVideo',
        method: 'POST',
        body: { chat_id, video, ...(caption ? { caption } : {}) },
      }),
    }),
    sendMediaGroup: builder.mutation({
      query: ({ chat_id, media, caption = null }) => ({
        url: '/sendMediaGroup',
        method: 'POST',
        body: { chat_id, media, ...(caption ? { caption } : {}) },
      }),
    }),
    getUploadURL: builder.query({
      query: ({ file_name }) => `/preSignedUrl?file_name=${file_name}`,
    }),
    getFriends: builder.query({
      query: () => '/getFriends',
    }),
    deleteChats: builder.mutation({
      query: ({ chat_ids }) => ({
        url: '/deleteChats',
        method: 'POST',
        body: { chat_ids },
      }),
    }),
    ...getQuickMessagesApi(builder),
  }),
});

export const {
  // Quick Messages API
  useCreateAnswerMutation,
  useDeleteAnswerMutation,
  useEditAnswerMutation,
  useGetAnswersQuery,
  useSetAutoAnswerMutation,
  useGetAutoAnswerQuery,
  // Quick Messages API

  useGetChatsQuery,
  useLazyGetChatsQuery,
  useLazyGetMessagesQuery,
  useGetMessagesQuery,
  useGetUserQuery,
  useGetChatQuery,
  useReadMessageMutation,
  useSendTextMutation,
  useSendPhotoMutation,
  useSendVideoMutation,
  useSendMediaGroupMutation,
  useGetUploadURLQuery,
  useLazyGetUploadURLQuery,
  useGetFriendsQuery,
  useDeleteChatsMutation,
} = api;
