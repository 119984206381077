/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';

import {
  useLazyGetUploadURLQuery,
  useSendMediaGroupMutation,
  useSendPhotoMutation,
  useSendVideoMutation,
} from 'services/api';

import {
  Box, Modal, Button, IconButton,
} from '@mui/material';
import { ReactComponent as Back } from 'pages/direct-message/assets/arrow-back.svg';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessageEntityThenHandler } from 'utils';
import { getCaption, setCaption } from 'redux/messages/slice';
import { Preview } from './PreviewComponent';
import { Layout } from './Layout';
import { SubmitButton } from './SubmitButton';
import { mapFileData } from './mapFileData';

function loadImage({ file, resolve }) {
  let img = '';

  const fileReader = new FileReader();
  fileReader.onload = createImage;
  fileReader.readAsDataURL(file);

  function createImage() {
    img = new Image();
    img.onload = imageLoaded;
    img.src = fileReader.result;
  }

  function imageLoaded() {
    const canvasWidth = img.width / 3;
    const canvasHeight = img.height / 3;
    const canvas = document.createElement('canvas');
    const imageWidth = img.width;
    const imageHeight = img.height;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const sizer = scalePreserveAspectRatio(
      imageWidth,
      imageHeight,
      canvasWidth,
      canvasHeight,
    );

    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      img,
      0,
      0,
      imageWidth,
      imageHeight,
      0,
      0,
      imageWidth * sizer,
      imageHeight * sizer,
    );
    const base64Img = canvas.toDataURL('image/jpeg', '0.6');

    resolve(base64Img);
  }
  function scalePreserveAspectRatio(imgW, imgH, maxW, maxH) {
    return Math.min(maxW / imgW, maxH / imgH);
  }
}

export async function dataUrlToFile(dataUrl, fileName) {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type: 'image/jpeg' });
}

const compressToBase64 = (file) => new Promise((resolve) => {
  loadImage({ file, resolve });
});

export const FileUploader = ({
  setOpen, open, chatId, user, limit,
}) => {
  const [getUploadURL] = useLazyGetUploadURLQuery();
  const dispatch = useDispatch();
  const caption = useSelector(getCaption);
  const [, setInfo] = useState({});
  const [sendPhoto] = useSendPhotoMutation();
  const [sendVideo] = useSendVideoMutation();
  const [sendMediaGroup] = useSendMediaGroupMutation();

  const getUploadParams = async ({ meta, file }) => {
    const fileNameNoExtension = meta?.name.split('.').slice(0, -1).join('.');
    const extension = meta?.name.split('.').slice(-1).join('.');
    const compressedBase64 = await compressToBase64(file);
    const previewFileName = `${fileNameNoExtension}-preview.${extension}`;
    const compressedFile = await dataUrlToFile(compressedBase64, previewFileName);
    const { data } = await getUploadURL({ file_name: meta?.name });
    const { data: previewData } = await getUploadURL({ file_name: previewFileName });
    const url = data?.data;
    const previewUrl = previewData?.data;

    await axios({
      method: 'PUT',
      url: previewUrl,
      data: compressedFile,
      headers: {
        'Content-Type': meta?.type,
      },
    });

    return {
      url,
      meta: {
        fileUrl: `${url.split('?')[0]}`,
        BEPreviewUrl: `${previewUrl.split('?')[0]}`,
      },
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': meta?.type,
      },
    };
  };

  const handleChangeStatus = ({ meta }, status) => {
    setInfo({ status, meta });
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = (files, allFiles) => {
    if (allFiles.length === 1) {
      const asyncRequestMap = {
        photo: sendPhoto,
        video: sendVideo,
      };
      const [item] = allFiles;

      const { entityData, entityName } = mapFileData(item);
      const sendHandler = asyncRequestMap[entityName];

      sendHandler({ chat_id: chatId, [entityName]: entityData, caption }).then(
        sendMessageEntityThenHandler({
          user,
          chatId,
          entityName,
          entityData,
          limit,
          dispatch,
          additionalData: { caption },
        }),
      );
    } else {
      const formattedItems = {
        items: allFiles
          .map(mapFileData)
          .map(({ entityData, entityName }) => ({ [entityName]: entityData })),
      };

      sendMediaGroup({ chat_id: chatId, media: formattedItems, caption }).then(
        sendMessageEntityThenHandler({
          user,
          chatId,
          entityName: 'media',
          entityData: formattedItems,
          limit,
          dispatch,
          additionalData: { caption },
        }),
      );
    }
    dispatch(setCaption(''));
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0, 0.7)',
        },
        display: 'flex',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          overflowY: 'scroll',
          height: 'calc(100% - 150px)',
          width: 'calc(100% - 30px)',
          margin: 'auto',
          '@media screen and (min-width: 1024px)': {
            height: 'auto',
          },
        }}
        tabIndex={null}
      >
        <IconButton
          sx={{
            backgroundColor: '#fff',
            position: 'absolute',
            top: 15,
            left: 7,
            zIndex: 999,
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
          onClick={handleClose}
        >
          <Back />
        </IconButton>
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          PreviewComponent={Preview}
          LayoutComponent={Layout}
          SubmitButtonComponent={SubmitButton}
          accept="image/*,video/*"
          inputContent={(
            <Button
              type="contained"
              onClick={() => {
                const input = document.querySelector('.dzu-input');
                input.click();
              }}
            >
              Add files
            </Button>
          )}
          styles={{
            inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
          }}
        />
      </Box>
    </Modal>
  );
};
