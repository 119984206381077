import { isIOS } from 'pages/direct-message/DirectMessage';

export const styles = {
  container: {
    backgroundColor: '#F4F4F4',
    display: 'flex',
    flexDirection: 'column',
    height: isIOS() ? '-webkit-fill-available' : '100vh',
    '@media screen and (min-width: 1024px)': {},
  },
  innerContainer: {
    display: 'flex',
    width: 'calc(100% - 20px)',
    height: '100%',
    margin: '0 auto',
    overflow: 'scroll',
    paddingBottom: '30px',
    marginBottom: '80px',
    '@media screen and (min-width: 1024px)': {
      marginBottom: 0,
      width: '100%',
      justifyContent: 'space-between',
      paddingLeft: '0',
      paddingBottom: 0,
      height: '100%',
      overflow: 'hidden',
    },
  },
  chatItem: {
    display: 'flex',
    padding: '8px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    marginTop: '12px',
    borderRadius: '8px',

    '&:hover': {
      backgroundColor: 'rgba(47, 128, 237, 0.1)',
    },
    '@media screen and (min-width: 1024px)': {
      marginTop: 0,
      padding: '12px',
      borderRadius: 0,
    },
  },
  nickname: {
    fontWeight: 500,
    fontSize: 14,
    paddingRight: '10px',
    maxWidth: '135px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  unreadCounter: {
    backgroundColor: '#2F80ED',
    color: '#fff',
    width: 25,
    height: 25,
    borderRadius: '100%',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
  },
  message: {
    color: '#4F5E7B',
    fontSize: 12,
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: '10px',
  },
  date: {
    color: '#4F5E7B',
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '&:last-child': {
      paddingTop: '10px',
    },
  },
  mainTextWrapper: {
    display: 'flex',
    width: '85%',
    alignItems: 'center',
    flexDirection: 'column',
  },
};
