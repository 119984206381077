import { Box } from '@mui/material';
import React from 'react';

import { styles } from '../styles';
import { ImagePreview } from './ImagePreview';
import { VideoPreview } from './VideoPreview';

export const MediaPreview = ({ items = [] }) => {
  const itemsToPreview = items.slice(0, 3);

  return (
    <Box
      sx={{
        ...styles.message,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        color: '#2F80ED',
      }}
    >
      {/* eslint-disable-next-line */}
      {itemsToPreview.map(({ photo, video }) => {
        if (photo) {
          return (
            <Box width="30px" height="30px">
              <ImagePreview {...photo} displayLabel={false} />
            </Box>
          );
        }

        if (video) {
          return (
            <Box width="30px" height="30px">
              <VideoPreview {...video} displayLabel={false} />
            </Box>
          );
        }
      })}
      <Box marginLeft="5px">Album</Box>
    </Box>
  );
};
