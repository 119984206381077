import React from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import ReCAPTCHA from 'react-google-recaptcha';

import { Box } from '@mui/material';

import { BackendCaptcha } from './BackendCaptcha';

export const CaptchaOverlay = ({
  isBECaptchaVisible,
  setIsBECaptchaVisible,
  captcha,
  setCaptcha,
  isLoading,
  handleSubmit,
  setCaptchaId,
  isReCaptchaVisible,
  onReCaptchaChange,
  onHCaptchaChange,
  isHCaptchaVisible,
}) => {
  return (
    <>
      {isBECaptchaVisible && (
        <BackendCaptcha
          open={isBECaptchaVisible}
          handleClose={() => setIsBECaptchaVisible(false)}
          captcha={captcha}
          setCaptcha={setCaptcha}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          setCaptchaId={setCaptchaId}
        />
      )}
      {isReCaptchaVisible && (
        <Box marginTop="20px">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SECRET}
            onChange={onReCaptchaChange}
          />
        </Box>
      )}
      {isHCaptchaVisible && (
        <Box marginTop="20px">
          <HCaptcha
            sitekey={process.env.REACT_APP_CRYPTO_KEY}
            onVerify={onHCaptchaChange}
            languageOverride="zh-CN"
          />
        </Box>
      )}
    </>
  );
};
