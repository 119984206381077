import React from 'react';
import { Box } from '@mui/material';

import { ReactComponent as Send } from '../../assets/send.svg';

export const QuickMessageItem = ({ text, onClick }) => {
  return (
    <Box
      onClick={onClick}
      key={text}
      sx={{
        backgroundColor: '#6C2FED',
        borderRadius: '28.5px',
        padding: '0 10px',
        display: 'flex',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          whiteSpace: 'nowrap',
          maxWidth: '50px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#fff',
          fontSize: '12px',
          marginRight: '4px',
        }}
      >
        {text}
      </Box>
      <Send width={10} />
    </Box>
  );
};
