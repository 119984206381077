import React from 'react';
import { Box } from '@mui/material';

import { styles } from './styles';

export const LAST_SEEN_VALUES = {
  NOW: 'now',
};

export const Avatar = ({
  avatar,
  lastSeen,
  avatarWrapperSx,
  width = 50,
  height = 50,
  onClick = () => {},
}) => {
  const isOnline = lastSeen === LAST_SEEN_VALUES.NOW;
  // eslint-disable-next-line no-nested-ternary
  const circleColor = isOnline ? '#4CE417' : lastSeen ? '#F2C94C' : '#BDBDBD';

  return (
    <Box sx={{ ...styles.avatarWrapper, ...avatarWrapperSx }} onClick={onClick}>
      <Box
        sx={{
          position: 'relative',
          height,
          width,
        }}
      >
        <img
          src={`https://static.moonscap.com${avatar}`}
          style={styles.avatar}
          alt="avatar"
        />
        {lastSeen && (
          <Box
            sx={{
              ...styles.circle,
              backgroundColor: circleColor,
            }}
          />
        )}
      </Box>
    </Box>
  );
};
