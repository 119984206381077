import { Events } from 'web-socket';
import { updateChatsToTop } from 'web-socket/utils/update-chats';
import NotificationSound from 'assets/notification.mp3';
import { api } from '../../services/api';

const audio = new Audio(NotificationSound);

export const handleMessageReceivedChats = async ({
  parsedData,
  chats,
  updateCachedData,
  dispatch,
  isNeedToAutoAnswer,
  autoAnswerMessage,
}) => {
  if (parsedData?.type === Events.MessageReceived) {
    const { message } = parsedData;

    const idxOfChat = chats.findIndex((chat) => chat.id === message?.chat_id);
    const isChatExist = idxOfChat !== -1;

    if (isChatExist) {
      updateCachedData((draft) => {
        const idxOfActiveChat = draft.data.list.findIndex(
          (chat) => chat.id === message?.chat_id,
        );

        const fieldsToUpdate = {
          last_message: message,
          unread_count: draft.data.list[idxOfActiveChat].unread_count + 1,
        };

        // eslint-disable-next-line no-param-reassign
        draft.data.list = updateChatsToTop({
          chats: draft.data.list,
          chatIdToUpdate: message?.chat_id,
          fieldsToUpdate,
        });
      });
    } else {
      const result = await dispatch(api.endpoints.getChat.initiate(message?.chat_id));
      const newChat = result?.data?.data;

      updateCachedData((draft) => {
        draft.data?.list.unshift(newChat);
      });
    }

    if (isNeedToAutoAnswer) {
      updateCachedData((draft) => {
        const idxOfActiveChat = draft.data.list.findIndex(
          (chat) => chat.id === autoAnswerMessage?.chat_id,
        );

        const fieldsToUpdate = {
          last_message: autoAnswerMessage,
          unread_count: draft.data.list[idxOfActiveChat].unread_count - 1,
        };

        // eslint-disable-next-line no-param-reassign
        draft.data.list = updateChatsToTop({
          chats: draft.data.list,
          chatIdToUpdate: autoAnswerMessage?.chat_id,
          fieldsToUpdate,
        });
      });
    }

    const options = {
      body: message?.text || 'You recieved new message',
      dir: 'ltr',
    };

    if (Notification) {
      // eslint-disable-next-line no-new
      new Notification('New message', options);
    }

    audio.play();
  }
};

export const handleMessageReceivedDirectMessage = ({
  parsedData,
  updateCachedData,
  isNeedToAutoAnswer,
  autoAnswerMessage,
  chatId = '',
}) => {
  if (parsedData?.type === Events.MessageReceived) {
    const { message } = parsedData;
    const isActiveChat = message?.chat_id === Number(chatId);

    if (isActiveChat) {
      updateCachedData((draft) => {
        draft.data.list.unshift(message);
      });

      if (isNeedToAutoAnswer) {
        updateCachedData((draft) => {
          draft.data.list.unshift(autoAnswerMessage);
        });
      }

      audio.play();
    }
  }
};
