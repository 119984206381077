import React from 'react';
import { Loader } from 'components/loader/Loader';

import { api, useGetChatsQuery, useGetFriendsQuery } from 'services/api';
import { ReactComponent as Friends } from 'assets/friends.svg';
import { ReactComponent as AddFriend } from 'assets/add-friend.svg';
import { Avatar } from 'components/avatar/Avatar';

import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'components/app-layout/AppLayout';
import { appRoutes } from 'routes/appRoutes';
import { useDispatch } from 'react-redux';

export const FriendsOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, isLoading } = useGetFriendsQuery();
  const { data: chatsData, isLoading: isChatsLoading } = useGetChatsQuery();

  const handleFriendClick = (opponent) => () => {
    const { data: chats } = chatsData;
    const chatsList = chats.list;

    const isChatExist = chatsList.find(({ id: chatId }) => chatId === opponent.id);

    if (!isChatExist) {
      const newChat = {
        id: opponent.id,
        last_message: {},
        last_read_message_id: null,
        opponent,
        opponent_last_read_message_id: null,
        unread_count: 0,
      };

      dispatch(
        api.util.updateQueryData('getChats', undefined, (draft) => {
          // eslint-disable-next-line no-param-reassign
          draft.data.list = [newChat, ...chatsList];
        }),
      );
    }
    if (isMobile) {
      navigate(appRoutes.DIRECT_MESSAGE.replace(':id', opponent.id));
    } else {
      navigate(`${appRoutes.HOME_PAGE}?chatId=${opponent.id}`);
    }
  };

  if (isLoading || isChatsLoading) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'auto',
        padding: '0 15px',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        height: 'calc(100% - 140px)',
        '@media screen and (min-width: 1024px)': {
          height: '100vh',
        },
      }}
    >
      <Box
        sx={{
          border: '1px solid #EDEDED',
          borderRadius: '4px',
          backgroundColor: '#fff',
          padding: '21px 16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: '16px',
            borderBottom: '1px solid #EAEAEA',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Friends />
            <Typography
              sx={{
                fontSize: 12,
                marginLeft: '16px',
              }}
            >
              {data?.data.length}
              {' '}
              好友
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <AddFriend />
          </Box>
        </Box>
        <Box marginTop="16px">
          {data?.data.map((friend) => {
            return (
              <Box
                key={friend.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '24px',
                  '&:first-child': {
                    marginTop: 0,
                  },
                }}
                onClick={handleFriendClick(friend)}
              >
                <Avatar
                  id={friend?.id}
                  avatar={friend.avatar}
                  lastSeen={friend?.last_seen}
                />
                <Typography
                  sx={{
                    color: '#1B1A57',
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  {friend.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
