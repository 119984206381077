import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  Button, Box, Grid, Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { LSUser, LSIMTokenName } from 'pages/login/constants';
import { getUser, setIMToken, setUser } from 'redux/auth/slice';
import { appRoutes } from 'routes/appRoutes';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { api } from 'services/api';
import { ProfileSkeleton } from './ProfileSkeleton';

export const MyProfile = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = '个人信息';
  }, []);

  const handleLogout = () => {
    dispatch(api.util.resetApiState());
    localStorage.removeItem(LSUser);
    localStorage.removeItem(LSIMTokenName);
    dispatch(setIMToken(null));
    dispatch(setUser(null));

    if (window.IMSocket) {
      window.IMSocket.close();
    }

    navigate(appRoutes.LOGIN);
    window.location.reload();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
    >
      <Box width="100%">
        <ProfileSkeleton
          id={user?.id}
          name={user?.nickname}
          avatar={`https://static.moonscap.com${user?.avatar}`}
        />
        <Box sx={{ padding: '0 36px', marginTop: '42px' }}>
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            sx={{
              width: '100%',
              cursor: 'pointer',
              justifyContent: 'space-between',
            }}
            onClick={() => {
              navigate(appRoutes.QUICK_MESSAGES);
            }}
          >
            <Box display="flex" alignItems="center">
              <SettingsIcon
                sx={{
                  '& path': {
                    fill: '#fff',
                  },
                  backgroundColor: '#2F80ED',
                  borderRadius: '30px',
                  padding: '3px',
                  marginRight: '16px',
                }}
              />
              <Typography
                sx={{
                  color: '#4F5E7B',
                  fontSize: 14,
                }}
              >
                设置快速回复
              </Typography>
            </Box>
            <ArrowForwardIosIcon
              sx={{
                width: 16,
                '& path': {
                  fill: '#4F5E7B',
                },
              }}
            />
          </Grid>
        </Box>
      </Box>

      <Box sx={{ height: 500 }}>
        <Button
          sx={{
            backgroundColor: '#3080ED',
            width: '210px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '19px 0',
            fontWeight: 700,
            color: '#fff',
            fontSize: 17,
            borderRadius: '10px',
            height: '38px',
            margin: '115px auto',
            '&:hover, &:active, &:focus': {
              backgroundColor: '#3080ED',
            },
          }}
          onClick={handleLogout}
        >
          退出登陆
        </Button>
      </Box>
    </Box>
  );
};
