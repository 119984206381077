import { Box } from '@mui/material';
import React from 'react';

export const TextMessage = ({ text }) => {
  return (
    <Box
      sx={{
        fontSize: 12,
        color: '#1B1A57',
        whiteSpace: 'pre-line',
      }}
    >
      {text}
    </Box>
  );
};
