import React, { useState } from 'react';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Avatar } from 'components/avatar/Avatar';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'routes/appRoutes';
import { UserProfilePopover } from 'components/user-profile-popover/UserProfilePopover';
import { isMobile } from 'components/app-layout/AppLayout';
import { getUser } from 'redux/auth/slice';
import { useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { MESSAGE_STATUSES } from 'pages/direct-message/constants';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export const OutgoingMessageSkeleton = ({
  id,
  avatar,
  lastSeen,
  sentAt,
  children,
  editedAt,
  status,
  activeChatData,
  handleSend,
}) => {
  const isMessageRead = activeChatData?.last_read_message_id >= id;

  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector(getUser);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: '0 16px',
        marginBottom: '16px',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#2F80ED',
          borderRadius: '8px',
          padding: '8px 10px',
          wordBreak: 'break-all',
          msWordBreak: 'break-all',
        }}
      >
        {children}

        <Box
          sx={{
            fontSize: 12,
            color: '#fff',
            paddingTop: '3px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          {editedAt && (
            <Box
              sx={{
                marginRight: '5px',
                fontSize: 12,
                fontStyle: 'italic',
              }}
            >
              edited
            </Box>
          )}
          {sentAt}
          {status === MESSAGE_STATUSES.LOADING ? (
            <CircularProgress
              size={12}
              sx={{
                color: '#fff',
              }}
            />
          ) : (
            <>
              {!isMessageRead && <CheckIcon sx={{ width: 16, height: 16 }} />}
              {isMessageRead && <DoneAllIcon sx={{ width: 16, height: 16 }} />}
            </>
          )}
          {status === MESSAGE_STATUSES.ERROR && (
            <IconButton
              sx={{ color: '#ff0202e3', width: 16, height: 16 }}
              onClick={handleSend}
            >
              <RestartAltIcon sx={{ width: 16, height: 16 }} />
            </IconButton>
          )}
        </Box>
      </Box>
      <Avatar
        lastSeen={lastSeen}
        avatar={avatar}
        width={40}
        height={40}
        avatarWrapperSx={{
          height: 'auto',
          width: 40,
          marginLeft: '16px',
          marginRight: 0,
          cursor: 'pointer',
        }}
        onClick={(e) => {
          if (isMobile) {
            navigate(appRoutes.MY_PROFILE);
          } else {
            setAnchorEl(e.currentTarget);
          }
        }}
      />

      <UserProfilePopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        id={user?.id}
        nickname={user?.nickname}
      />
    </Box>
  );
};
