import React from 'react';
import { Toaster } from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { store } from 'redux/store';
import { theme } from 'theme';

import { App } from './App';

const container = document.getElementById('root');
const root = createRoot(container);

Sentry?.init({
  dsn: 'https://fcb494cd4747409bae757dedf0736c40@ingest.sentry.northmarlin.com/7',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

root.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Provider store={store}>
        <React.StrictMode>
          <Toaster />
          <CssBaseline />
          <App />
        </React.StrictMode>
      </Provider>
    </Router>
  </ThemeProvider>,
);
