import React, { useEffect, useState } from 'react';

import { parsePhoneNumber } from 'react-phone-number-input';

import {
  Box, Button, TextField, CircularProgress,
} from '@mui/material';

import { login, useLoginIMMutation, useLoginMutation } from 'services/login';

import { getToken, setIMToken, setUser } from 'redux/auth/slice';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { appRoutes } from 'routes/appRoutes';
import { CaptchaOverlay } from './components/CaptchaOverlay';
import { SmsVerification } from './components/SmsVerification';
import { styles } from './styles';
import { PhoneInput } from './PhoneInput';
import { LSIMTokenName, LSUser } from './constants';

export const CLOSED_SOCKET_STATE = 3;

export const Login = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getToken);
  const [getImToken, { isLoading: isIMLoading }] = useLoginIMMutation();
  const [captcha, setCaptcha] = useState('');
  const [captchaId, setCaptchaId] = useState('');
  const [isBECaptchaVisible, setIsBECaptchaVisible] = useState(false);

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const [isHCaptchaVisible, setIsHCaptchaVisible] = useState(false);
  const [hCaptcha, setHCaptcha] = useState('');

  const [isReCaptchaVisible, setIsReCaptchaVisible] = useState(false);
  const [reCaptcha, setReCaptcha] = useState('');

  const [isSmsVerificationVisible, setIsSmsVerificationVisible] = useState(false);
  const [smsCode, setSmsCode] = useState('');

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [sendLoginData, { isLoading }] = useLoginMutation();

  const handleSubmit = () => {
    setIsErrorVisible(false);
    dispatch(login.util.resetApiState());
    const { countryCallingCode, nationalNumber } = parsePhoneNumber(String(phone)) ?? {};
    sendLoginData({
      username: nationalNumber,
      password,
      country_code: `+${countryCallingCode}`,
      ...(hCaptcha.length ? { hCaptcha } : {}),
      ...(smsCode.length ? { login_sms_captcha: smsCode } : {}),
      ...(captchaId.length ? { captcha_id: captchaId } : {}),
      ...(captcha.length ? { captcha } : {}),
      ...(reCaptcha.length ? { recaptcha: reCaptcha } : {}),
    }).then((response) => {
      const responseCode = Number(response?.data?.code);
      const token = response?.data?.data?.jwt_token;

      if (responseCode === 0) {
        dispatch(setUser(response?.data?.data?.user));
        getImToken({ token }).then(({ data }) => {
          const IMToken = data?.data;
          // set IM token to local storage
          // set IM Token to redux
          localStorage.setItem(LSIMTokenName, IMToken);
          localStorage.setItem(LSUser, JSON.stringify(response?.data?.data?.user));
          dispatch(setIMToken(IMToken));
          // const loginSocketData = JSON.stringify({
          //   type: 'Login',
          //   token: IMToken,
          //   device_id: `${navigator.userAgent.slice(0, 10)} - id`,
          //   device_name: `${navigator.userAgent.slice(0, 10)} - name`,
          // });
          // const isSocketClosed =
          //   window.IMSocket && window.IMSocket.readyState === CLOSED_SOCKET_STATE;

          // if (isSocketClosed) {
          //   const socket = new WebSocket(socketURL?.replace('https', 'wss'));
          //   window.IMSocket = socket;
          //   socket.onopen = () => {
          //     setTimeout(() => {
          //       socket.send(loginSocketData);
          //     }, 1000);
          //   };
          // } else {
          //   setTimeout(() => {
          //     window.IMSocket.send(loginSocketData);
          //   }, 1000);
          // }
        });
      } else if (responseCode === 1) {
        setIsErrorVisible(true);
      } else if (responseCode === 2) {
        setIsReCaptchaVisible(true);
      } else if (responseCode === 3) {
        setIsSmsVerificationVisible(true);
      } else if (responseCode === 4) {
        setIsHCaptchaVisible(true);
      } else if (responseCode === 5) {
        setIsBECaptchaVisible(true);
      }

      setCaptcha('');
      setCaptchaId('');
      setHCaptcha('');
      setReCaptcha('');
    });
  };

  const onReCaptchaChange = (value) => {
    setReCaptcha(value);
    handleSubmit();
  };

  const onHCaptchaChange = (value) => {
    setHCaptcha(value);
    handleSubmit();
  };

  useEffect(() => {
    document.title = '登录';
  }, []);

  if (isAuthenticated) {
    return <Navigate to={appRoutes.HOME_PAGE} />;
  }

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          maxWidth: 500,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontSize: '35px',
            fontWeight: 700,
            marginBottom: '21px',
            color: '#1C1939',
          }}
        >
          欢迎使用
        </Box>

        <Box
          sx={{
            color: 'rgba(28, 25, 57, 0.8)',
            fontWeight: 400,
            fontSize: 15,
          }}
        >
          登录账号
        </Box>
        <Box
          sx={{
            marginTop: '80px',
            width: 'calc(100% - 40px)',
          }}
        >
          <PhoneInput
            international
            value={phone}
            setValue={setPhone}
            placeholder="请输入您的手机号"
            defaultCountry="CN"
          />

          <Box
            sx={{
              marginTop: '5px',
            }}
          >
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="standard"
              type="password"
              placeholder="请输入您的密码"
              sx={styles.textField}
            />
          </Box>
          {isSmsVerificationVisible && (
            <SmsVerification
              open={isSmsVerificationVisible}
              setSmsCode={setSmsCode}
              smsCode={smsCode}
              handleSubmit={handleSubmit}
              handleClose={() => setIsSmsVerificationVisible(false)}
              isLoading={isLoading || isIMLoading}
            />
          )}
          <CaptchaOverlay
            isBECaptchaVisible={isBECaptchaVisible}
            setIsBECaptchaVisible={setIsBECaptchaVisible}
            captcha={captcha}
            setCaptcha={setCaptcha}
            isLoading={isLoading || isIMLoading}
            handleSubmit={handleSubmit}
            setCaptchaId={setCaptchaId}
            isReCaptchaVisible={isReCaptchaVisible}
            onReCaptchaChange={onReCaptchaChange}
            isHCaptchaVisible={isHCaptchaVisible}
            onHCaptchaChange={onHCaptchaChange}
          />
          {isErrorVisible && (
            <Box
              sx={{
                color: '#FF8484',
                fontSize: 12,
                fontWeight: 400,
                marginTop: '20px',
              }}
            >
              密码或账号错误，请重新输入！
            </Box>
          )}

          <Button
            sx={styles.btn}
            disabled={!phone || !password || isLoading || isIMLoading}
            onClick={handleSubmit}
          >
            {isLoading || isIMLoading ? (
              <CircularProgress
                sx={{
                  '& circle': {
                    stroke: '#fff',
                  },
                }}
              />
            ) : (
              <>登录</>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
