export const mapFileData = (item) => {
  const {
    type,
    fileUrl,
    BEPreviewUrl,
    width,
    height,
    videoHeight,
    videoWidth,
    videoPreviewUrl,
    name,
    size,
  } = item.meta;

  const isImage = type.includes('image');

  const photo = {
    url: fileUrl,
    preview: BEPreviewUrl,
    width: width || 500,
    height: height || 500,
    mime_type: type,
    file_name: name,
    file_size: size,
  };

  const video = {
    url: fileUrl,
    preview: videoPreviewUrl,
    width: videoWidth || 500,
    height: videoHeight || 500,
    mime_type: type,
    file_name: name,
    file_size: size,
    duration: Math.round(item?.meta.duration),
  };

  return {
    entityData: isImage ? photo : video,
    entityName: isImage ? 'photo' : 'video',
  };
};
