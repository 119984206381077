import React from 'react';
import {
  Box,
  TextField,
  Modal,
  IconButton,
  CircularProgress,
  Button,
  Grid,
} from '@mui/material';
import Countdown from 'react-countdown';

import CloseIcon from '@mui/icons-material/Close';

import { styles } from '../styles';

const renderer = ({ seconds }) => (
  <Grid container alignItems="center" justifyContent="center">
    <Grid
      container
      item
      alignItems="center"
      justifyContent="center"
      sx={{
        borderRadius: '50%',
        width: 35,
        height: 35,
        border: '2px solid #2F9BD7',
      }}
    >
      {seconds}
    </Grid>
  </Grid>
);

export const SmsVerification = ({
  open,
  handleClose,
  smsCode,
  setSmsCode,
  isLoading,
  handleSubmit,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0, 0.7)',
        },
      }}
    >
      <Box height="100%" display="flex" alignItems="center">
        <Box
          width="calc(100% - 60px)"
          margin="0 auto"
          backgroundColor="#fff"
          padding="24px"
          position="relative"
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 5,
              right: 15,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box display="flex" justifyContent="center" width="100%">
            <Countdown date={Date.now() + 59000} renderer={renderer} />
          </Box>

          <Box
            sx={{
              marginTop: '15px',
            }}
          >
            <TextField
              value={smsCode}
              onChange={(e) => setSmsCode(e.target.value)}
              variant="standard"
              type="text"
              placeholder="请输入将通过短信发送的验证码"
              sx={styles.textField}
            />
          </Box>
          <Button
            sx={{
              ...styles.btn,
              marginTop: '20px',
            }}
            disabled={isLoading || !smsCode.length}
            onClick={() => {
              handleSubmit();
              handleClose();
            }}
          >
            {!isLoading ? (
              <>登录</>
            ) : (
              <CircularProgress
                sx={{
                  '& circle': {
                    stroke: '#fff',
                  },
                }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
