import {
  Box, CardMedia, Modal, IconButton,
} from '@mui/material';
import LazyLoad from 'react-lazy-load';
import React, { useState } from 'react';
import { isMobile } from 'components/app-layout/AppLayout';
import { ReactComponent as Back } from '../../assets/arrow-back.svg';

const FullImageView = ({ setOpen, open, url }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : 'auto',
    ...(!isMobile && { height: '100vh' }),
    outline: 'none',
  };

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0, 0.7)',
        },
      }}
    >
      <>
        <IconButton
          sx={{
            backgroundColor: '#fff',
            position: 'absolute',
            top: 15,
            left: 7,
            zIndex: 999,
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
          onClick={handleClose}
        >
          <Back />
        </IconButton>
        <Box sx={style}>
          <CardMedia
            component="img"
            image={url}
            alt="fullImage"
            width="100%"
            height="100%"
            sx={{
              padding: isMobile ? '50px' : '20px',

              ...(!isMobile && { objectFit: 'contain' }),
            }}
          />
        </Box>
      </>
    </Modal>
  );
};

export const ImageWithPreview = ({
  height,
  preview,
  url,
  width,
  caption,
  captionColor = '#1B1A57',
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  return (
    <>
      <LazyLoad width="100%" height="100%" threshold={0.5}>
        <CardMedia
          component="img"
          image={preview}
          alt="preview"
          width="100%"
          height="100%"
          onClick={handleOpen}
        />
      </LazyLoad>
      {caption && (
        <Box
          sx={{
            fontSize: '12px',
            color: captionColor,
          }}
        >
          {caption}
        </Box>
      )}
      <FullImageView
        setOpen={setOpen}
        open={open}
        url={url}
        width={width}
        height={height}
      />
    </>
  );
};
