import { Chats } from 'pages/chats/Chats';
import { MyProfile } from 'pages/profile/MyProfile';
import { UserProfile } from 'pages/profile/UserProfile';
import { DirectMessage } from 'pages/direct-message/DirectMessage';
import { Login } from 'pages/login/Login';
import { FriendsOverview } from 'pages/friends/FriendsOverview';
import { QuickMessages } from 'pages/quick-messages/QuickMessages';

export const appRoutes = {
  HOME_PAGE: '/',
  USER_PROFILE: '/im/user-profile/:id',
  MY_PROFILE: '/im/my-profile',
  DIRECT_MESSAGE: '/im/direct-message/:id',
  LOGIN: '/im/login',
  FRIENDS: '/im/friends',
  QUICK_MESSAGES: '/im/quick-messages',
};

export const privateRoutesConfig = [
  {
    path: appRoutes.HOME_PAGE,
    Element: Chats,
  },
  {
    path: appRoutes.DIRECT_MESSAGE,
    Element: DirectMessage,
  },
  {
    path: appRoutes.USER_PROFILE,
    Element: UserProfile,
  },
  {
    path: appRoutes.MY_PROFILE,
    Element: MyProfile,
  },
  {
    path: appRoutes.FRIENDS,
    Element: FriendsOverview,
  },
  {
    path: appRoutes.QUICK_MESSAGES,
    Element: QuickMessages,
  },
];

export const publicRoutesConfig = [
  {
    path: appRoutes.LOGIN,
    Element: Login,
  },
];
