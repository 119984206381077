import { Grid, Typography } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

import React from 'react';
import { ReactComponent as Friends } from './assets/friends.svg';

import { appRoutes } from '../../routes/appRoutes';

export const FriendsMenuItem = ({ onClick }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      sx={{
        width: 90,
        '& .friends': {
          fill: location.pathname === appRoutes.FRIENDS ? '#2F80ED' : '#4F5E7B',
        },
        cursor: 'pointer',
      }}
      onClick={() => {
        navigate(appRoutes.FRIENDS);
        onClick?.();
      }}
    >
      <Friends />
      <Typography
        sx={{
          color: location.pathname === appRoutes.FRIENDS ? '#2F80ED' : '#4F5E7B',
          fontSize: 12,
        }}
      >
        好友列表
      </Typography>
    </Grid>
  );
};
