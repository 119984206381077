import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, Box } from '@mui/material';

import { LAST_SEEN_VALUES } from 'components/avatar/Avatar';

import { convertTimeToLabel } from 'utils';

import { getUser } from 'redux/auth/slice';
import { useSelector } from 'react-redux';
import { IncomingMessage } from '../incoming-message/IncomingMessage';
import { OutgoingMessage } from '../outgoing-message/OutgoingMessage';

export const MessagesList = ({
  data = [],
  opponentData = {},
  id,
  fetchMoreData,
  isLoading,
  activeChatData,
  handleSend,
}) => {
  const user = useSelector(getUser);

  return (
    <InfiniteScroll
      inverse
      hasMore
      dataLength={data.length}
      next={fetchMoreData}
      style={{ display: 'flex', flexDirection: 'column-reverse' }}
      loader={isLoading && <CircularProgress size={20} />}
      scrollableTarget="scrollableDiv"
    >
      {data.map((message) => {
        if (message?.service_text) {
          return (
            <Box
              key={message.id}
              sx={{
                display: 'flex',
                marginBottom: '16px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'rgba(0,0,0, .43)',
                  padding: '5px 10px',
                  borderRadius: '30px',
                  color: '#fff',
                  maxWidth: '50%',
                }}
              >
                {message?.service_text}
              </Box>
            </Box>
          );
        }

        if (Number(message.sender_id) === Number(id)) {
          return (
            <IncomingMessage
              key={message.id}
              avatar={opponentData?.avatar}
              lastSeen={
                opponentData?.last_seen === LAST_SEEN_VALUES.NOW
                  ? opponentData?.last_seen
                  : convertTimeToLabel(Number(opponentData?.last_seen))
              }
              name={opponentData?.name}
              text={message?.text}
              sentAt={convertTimeToLabel(Number(message.sent_at))}
              opponentId={opponentData?.id || ''}
              {...message}
            />
          );
        }

        return (
          <OutgoingMessage
            key={message.id}
            avatar={user?.avatar}
            lastSeen="now"
            text={message?.text}
            sentAt={convertTimeToLabel(Number(message.sent_at))}
            id={user?.id}
            activeChatData={activeChatData}
            handleSend={handleSend}
            {...message}
          />
        );
      })}
    </InfiniteScroll>
  );
};
