import { Box, IconButton } from '@mui/material';
import { isMobile } from 'components/app-layout/AppLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { appRoutes } from 'routes/appRoutes';
import { LAST_SEEN_VALUES } from '../../components/avatar/Avatar';

import { ReactComponent as Back } from './assets/arrow-back.svg';

import { styles } from './styles';

export const Header = ({
  avatar, name, lastSeen, isTyping, onClick,
}) => {
  const navigate = useNavigate();

  const isOnline = lastSeen === LAST_SEEN_VALUES.NOW;

  return (
    <Box sx={styles.header}>
      {isMobile && (
        <IconButton
          onClick={() => {
            navigate(appRoutes.HOME_PAGE);
          }}
        >
          <Back />
        </IconButton>
      )}

      <Box sx={styles.userInfo}>
        <Box sx={styles.avatarWrapper} onClick={onClick}>
          <img src={avatar} style={styles.avatar} alt="avatar" />
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box
            sx={{
              fontSize: '18px',
              fontWeight: 500,
            }}
          >
            {name}
          </Box>
          <Box
            sx={{
              fontSize: 12,
              color: isOnline ? '#00C013' : '#4F5E7B',
            }}
          >
            {isTyping ? (
              <Box
                sx={{
                  fontSize: 12,
                  color: '#4F5E7B',
                }}
              >
                typing...
              </Box>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {isOnline ? (
                  <>当前在线</>
                ) : (
                  <>
                    最近上线时间
                    {lastSeen}
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
