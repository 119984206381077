import { Events } from './event-types';

const pingEvent = () => {
  setTimeout(() => {
    setInterval(() => {
      if (window.IMSocket && window.IMSocket.readyState === WebSocket.OPEN) {
        window.IMSocket.send(
          JSON.stringify({
            type: Events.Ping,
          }),
        );
      }
    }, 20000);
  }, 2000);
};

export function getLoginData({ token }) {
  return {
    type: 'Login',
    token,
    device_id: `${navigator.userAgent.slice(0, 10)} - id`,
    device_name: `${navigator.userAgent.slice(0, 10)} - name`,
  };
}

export const onSocketOpen = (socket, token) => () => {
  socket.send(JSON.stringify(getLoginData({ token })));

  pingEvent();
};
