import { Box, IconButton } from '@mui/material';
import React from 'react';
import { ReactComponent as Back } from 'pages/direct-message/assets/arrow-back.svg';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'components/app-layout/AppLayout';
import { QuickMessagesForm } from './QuickMessagesForm';

export const QuickMessages = () => {
  const navigate = useNavigate();

  return (
    <Box
      position="relative"
      overflow="auto"
      sx={{
        height: 'calc(100vh - 80px)', // bottom menu height
        '@media screen and (min-width: 1024px)': {
          height: 'auto',
        },
      }}
    >
      {isMobile && (
        <Box sx={{ position: 'absolute', left: '8px', top: '10px' }}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <Back />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '20px',
          marginTop: '16px',
        }}
      >
        设置快速回复
      </Box>

      <QuickMessagesForm />
    </Box>
  );
};
