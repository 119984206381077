/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  caption: '',
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setCaption: (state, { payload }) => {
      state.caption = payload;
    },
  },
});

export const { setCaption } = messagesSlice.actions;

const selectMessages = (state) => state.messages;

export const getCaption = createSelector(selectMessages, (messages) => messages.caption);

export default messagesSlice.reducer;
