import {
  Box, CardMedia, IconButton, Modal,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { isMobile } from 'components/app-layout/AppLayout';
import { ReactComponent as Back } from '../../assets/arrow-back.svg';

const FullVideoView = ({ setOpen, open, url }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : 'auto',
    outline: 'none',
    p: 4,
  };

  const videoRef = useRef(null);

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0, 0.7)',
        },
      }}
    >
      <>
        <IconButton
          sx={{
            backgroundColor: '#fff',
            position: 'absolute',
            top: 15,
            left: 7,
            zIndex: 999,
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
          onClick={handleClose}
        >
          <Back />
        </IconButton>
        <Box sx={style}>
          <CardMedia
            ref={videoRef}
            component="video"
            src={url}
            width="100%"
            height="100%"
            controls
            autoPlay
          />
        </Box>
      </>
    </Modal>
  );
};

export const VideoWithPreview = ({
  url, preview, caption, captionColor = '#1B1A57',
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  return (
    <Box position="relative" overflow="hidden" width="100%" height="100%">
      <CardMedia
        component="img"
        image={preview}
        alt="video-preview"
        width="100%"
        height="100%"
        onClick={handleOpen}
      />
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        onClick={handleOpen}
      >
        <PlayCircleFilledWhiteIcon
          sx={{
            width: 50,
            height: 50,
            fill: '#fff',
          }}
        />
      </IconButton>
      {caption && (
        <Box
          sx={{
            fontSize: '12px',
            color: captionColor,
          }}
        >
          {caption}
        </Box>
      )}
      <FullVideoView setOpen={setOpen} open={open} url={url} />
    </Box>
  );
};
