import { Events } from 'web-socket';
import { updateChats } from 'web-socket/utils/update-chats';

export const handleUserTyping = ({
  parsedData,
  updateCachedData,
  updateChatCachedData,
}) => {
  if (
    parsedData?.type === Events.UserStartedTyping
    || parsedData?.type === Events.UserEndedTyping
  ) {
    const { chat_id, user_id } = parsedData;
    const isTyping = parsedData?.type === Events.UserStartedTyping;

    updateCachedData((draft) => {
      const chats = draft.data.list;

      const idxOfChat = chats?.findIndex((chat) => chat?.opponent?.id === user_id);

      const isStartedTypingAndNoIsTyping = parsedData?.type === Events.UserStartedTyping && !chats[idxOfChat]?.isTyping;
      const isEndedTypingAndIsTyping = parsedData?.type === Events.UserEndedTyping && chats[idxOfChat]?.isTyping;

      if (
        idxOfChat !== -1
        && (isStartedTypingAndNoIsTyping || isEndedTypingAndIsTyping)
      ) {
        // eslint-disable-next-line no-param-reassign
        draft.data.list = updateChats({
          chats,
          opponentId: user_id,
          fieldsToUpdate: {
            isTyping,
          },
        });
      }
    });

    updateChatCachedData((draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.data = {
        ...draft.data,
        isTyping,
      };
    }, chat_id);
  }
};
