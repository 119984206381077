import { CardMedia, Box } from '@mui/material';

import React from 'react';

import { styles } from '../styles';

export const ImagePreview = ({ url, displayLabel = true }) => {
  return (
    <Box
      sx={{
        ...styles.message,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        color: '#2F80ED',
        paddingRight: 0,
        marginRight: '3px',
      }}
    >
      <Box width="30px" height="30px">
        <CardMedia
          component="img"
          src={url}
          width="100%"
          height="100%"
          sx={{
            borderRadius: '3px',
          }}
        />
      </Box>
      {displayLabel && <Box marginLeft="5px">Photo</Box>}
    </Box>
  );
};
