import React, { useMemo, useRef } from 'react';
import axios from 'axios';

import { CardMedia, ImageListItem } from '@mui/material';
import { useLazyGetUploadURLQuery } from 'services/api';
import { dataURLtoFile } from 'utils';

export const Preview = ({ fileWithMeta, meta: { previewUrl } }) => {
  const [getUploadURL] = useLazyGetUploadURLQuery();
  const playerRef = useRef(null);
  const videoPreviewUrl = useMemo(
    () => !previewUrl && URL.createObjectURL(fileWithMeta.file),
    [],
  );

  return (
    <>
      {previewUrl && (
        <ImageListItem
          key={previewUrl}
          sx={{
            overflow: 'hidden',
          }}
        >
          <CardMedia
            component="img"
            src={previewUrl}
            width="100%"
            height="100%"
            sx={{
              borderRadius: '3px',
              objectFit: 'contain',
            }}
          />
        </ImageListItem>
      )}

      {!previewUrl && (
        <ImageListItem
          key={previewUrl}
          sx={{
            overflow: 'hidden',
          }}
        >
          <video
            ref={playerRef}
            src={videoPreviewUrl}
            style={{
              width: '100%',
              height: '100%',
            }}
            controls
            onLoadedMetadata={async () => {
              const canvas = document.createElement('canvas');
              canvas.style.display = 'none';

              await new Promise((resolve) => {
                canvas.width = playerRef.current.videoWidth;
                canvas.height = playerRef.current.videoHeight;

                playerRef.current.currentTime = playerRef.current.duration * 0.25;

                playerRef.current.addEventListener('seeked', () => resolve());
              });

              canvas
                .getContext('2d')
                .drawImage(
                  playerRef.current,
                  0,
                  0,
                  playerRef.current.videoWidth,
                  playerRef.current.videoHeight,
                );
              const imageUrl = canvas.toDataURL('image/jpeg', 0.1);
              const fileImage = dataURLtoFile(imageUrl);

              const { data } = await getUploadURL({
                file_name: `${fileWithMeta?.meta?.name}-preview.jpeg`,
              });
              const videoPreviewURL = data?.data;

              axios.put(videoPreviewURL, fileImage, {
                headers: {
                  'Content-Type': 'image/*',
                  Accept: '*/*',
                },
              });

              const videoPreviewUrlSplitted = videoPreviewURL.split('?')[0];

              // eslint-disable-next-line no-param-reassign
              fileWithMeta.meta.videoPreviewUrl = videoPreviewUrlSplitted;
            }}
          />
        </ImageListItem>
      )}
    </>
  );
};
