export const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 8px',
    borderBottom: '1px solid lightgray',
  },
  userInfo: {
    display: 'flex',
  },
  avatarWrapper: {
    position: 'relative',
    height: 64,
    width: 64,
    marginRight: '16px',
  },
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
  },
};
