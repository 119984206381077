import { Checkbox } from '@mui/material';
import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useSetAutoAnswerMutation } from 'services/api';

export const QuickMessagesFormCheckbox = ({
  id, control, index, setValue,
}) => {
  const quickMessages = useWatch({ control, name: 'quickMessages' });
  const autoAnswer = useWatch({ control, name: 'autoAnswer' });
  const [setAutoAnswer] = useSetAutoAnswerMutation();

  return (
    <Controller
      control={control}
      name={`quickMessages.${index}.checked`}
      render={({ field }) => (
        <Checkbox
          {...field}
          disabled={!id}
          sx={{
            width: 30,
            height: 30,
            marginTop: '12px',
          }}
          checked={field.value}
          onChange={(event) => {
            field.onChange(event);

            const updatedQuickMessages = quickMessages.map((fieldValue) => ({
              ...fieldValue,
              ...(fieldValue.id === id && event.target.checked
                ? { checked: true }
                : { checked: false }),
            }));

            const isSelectedQuickMessages = updatedQuickMessages.some(
              ({ checked }) => checked,
            );

            setValue('quickMessages', updatedQuickMessages);

            if (!isSelectedQuickMessages) {
              setValue('autoAnswer', false);
              setAutoAnswer({ id: null });
            } else if (autoAnswer && event.target.checked) {
              setAutoAnswer({ id });
            }
          }}
        />
      )}
    />
  );
};
