/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, TextField, ImageList } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCaption, setCaption } from 'redux/messages/slice';

export const Layout = ({
  input, previews, submitButton, files,
}) => {
  const caption = useSelector(getCaption);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (previewRef && previewRef.current) {
  //     previewRef.current.scrollTop = previewRef.current.scrollHeight;
  //   }
  // }, [previews]);

  if (!previews.length) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        {input}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '@media screen and (min-width: 1024px)': {
          paddingBottom: '50px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          paddingRight: '20px',
          justifyContent: 'flex-end',
        }}
      >
        {files.length > 0 && submitButton}
      </Box>
      <ImageList
        sx={{
          maxHeight: 400,
          width: '100%',
          padding: '0 10px',
        }}
        cols={
          previews.length === 1
            ? 1
            : previews.length === 2
              ? 2
              : previews.length === 3
                ? 3
                : 3
        }
        rowHeight={400}
      >
        {previews}
      </ImageList>
      <Box width="100%" height="50px" marginTop="10px" padding="0 10px">
        <TextField
          value={caption}
          onChange={(e) => dispatch(setCaption(e.target.value))}
          variant="outlined"
          placeholder="Add a caption..."
          sx={{
            width: '100%',
          }}
        />
      </Box>
    </Box>
  );
};
