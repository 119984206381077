import React from 'react';
import 'react-phone-number-input/style.css';
import './phone.css';
import PhoneInputLib from 'react-phone-number-input';

export const PhoneInput = ({
  value, setValue, placeholder, sx, ...props
}) => {
  return (
    <PhoneInputLib
      placeholder={placeholder}
      value={value}
      onChange={setValue}
      style={sx}
      {...props}
    />
  );
};
