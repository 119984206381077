import { isIOS } from 'pages/direct-message/DirectMessage';

export const styles = {
  container: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    height: isIOS() ? '-webkit-fill-available' : '100vh',
  },
};
