import { useDispatch } from 'react-redux';
import { api, useReadMessageMutation } from 'services/api';
import { updateChats } from 'web-socket/utils/update-chats';

export const useReadMessage = () => {
  const dispatch = useDispatch();
  const [readMessage] = useReadMessageMutation();

  const readMessageFn = ({
    chat_id, message_id, unread_count, opponentId,
  }) => {
    if (unread_count) {
      readMessage({ chat_id, message_id });
      dispatch(
        api.util.updateQueryData('getChats', undefined, (draft) => {
          const chats = draft.data.list;

          // eslint-disable-next-line no-param-reassign
          draft.data.list = updateChats({
            opponentId,
            chats,
            fieldsToUpdate: {
              unread_count: 0,
            },
          });
        }),
      );
    }
  };

  return {
    readMessage: readMessageFn,
  };
};
