export function tryParseJSONObject(jsonString) {
  try {
    const parsedObject = JSON.parse(jsonString);

    if (parsedObject && typeof parsedObject === 'object') {
      return parsedObject;
    }
  } catch (e) {
    console.error(e);
  }

  return false;
}
