import { Box, Button, Modal } from '@mui/material';
import React from 'react';

export const QuickMessagesDeleteModal = ({ open, setOpen, remove }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0, 0.7)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          alignItems: 'center',
          padding: '16px',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            fontWeight: 500,
            fontSize: 14,
          }}
        >
          确定删除?
        </Box>
        <Box marginTop="16px">
          <Button type="contained" onClick={() => setOpen(false)}>
            否
          </Button>

          <Button
            type="contained"
            sx={{
              backgroundColor: '#FF8484',
              color: '#fff',
              marginLeft: '16px',
              '&:hover': {
                backgroundColor: '#FF8484',
              },
            }}
            onClick={() => {
              remove();
            }}
          >
            是
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
