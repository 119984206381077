import { createTheme } from '@mui/material';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1921,
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'PingFang SC, sans-serif',
    },
  },
});
