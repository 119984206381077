export const updateChatsToTop = ({ chats, chatIdToUpdate, fieldsToUpdate }) => {
  const idxOfActiveChat = chats.findIndex((chat) => chat.id === chatIdToUpdate);
  if (idxOfActiveChat === -1) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return [
    {
      ...chats[idxOfActiveChat],
      ...fieldsToUpdate,
    },
    ...chats.slice(0, idxOfActiveChat),
    ...chats.slice(idxOfActiveChat + 1),
  ];
};

export const updateChats = ({ chats, opponentId, fieldsToUpdate }) => {
  const idxOfActiveChat = chats.findIndex((chat) => chat?.opponent?.id === opponentId);

  return chats.map((chat, idx) => {
    if (idx === idxOfActiveChat) {
      return {
        ...chat,
        ...fieldsToUpdate,
      };
    }
    return chat;
  });
};
