export const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 8px',
    borderBottom: '1px solid lightgray',
    position: 'fixed',
    width: '100%',
    backgroundColor: '#fff',
    zIndex: 999,
    '@media screen and (min-width: 1024px)': {
      position: 'static',
      paddingLeft: '16px',
    },
  },
  userInfo: {
    display: 'flex',
  },
  avatarWrapper: {
    position: 'relative',
    height: 50,
    width: 50,
    marginRight: '16px',
    cursor: 'pointer',
  },
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
  },
  unreadCounter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '18px',
    height: '18px',
    background: '#fff',
    fontSize: '12px',
    top: -9,
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#1B1A57',
    borderRadius: '100%',
  },
};
