import React, { useEffect } from 'react';

import { Box, CircularProgress, Grid } from '@mui/material';

import { convertTimeToLabel } from 'utils';

import { appRoutes } from 'routes/appRoutes';

import { useReadMessage } from 'hooks/useReadMessage';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { isMobile } from 'components/app-layout/AppLayout';
import { DirectMessage } from 'pages/direct-message/DirectMessage';
import { useGetChatsQuery } from '../../services/api';
import { styles } from './styles';
import { ChatItem } from './ChatItem';

export const Chats = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetChatsQuery();
  const { readMessage } = useReadMessage();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          backgroundColor: '#fff',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '15px',
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        聊天
      </Box>
      <Grid container sx={styles.innerContainer}>
        {isLoading ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid
            item
            md={4}
            xs={12}
            sx={{
              '@media screen and (min-width: 1024px)': {
                height: 'calc(100% - 42px)',
                overflow: 'auto',
                width: '30%',
              },
            }}
          >
            {(data?.data?.list || []).map(
              ({
                unread_count = 0,
                isTyping,
                channel = null,
                opponent = null,
                ...rest
              }) => {
                if (channel) {
                  return null;
                }
                const convertedSentAt = convertTimeToLabel(
                  rest?.last_message?.sent_at || '',
                );
                const onItemClick = () => {
                  readMessage({
                    unread_count,
                    chat_id: rest?.last_message?.chat_id,
                    message_id: rest?.last_message?.id,
                    opponentId: opponent?.id,
                  });
                  if (isMobile) {
                    navigate(appRoutes.DIRECT_MESSAGE.replace(':id', opponent?.id));
                  } else {
                    setSearchParams({ chatId: opponent?.id });
                  }
                };

                return (
                  <ChatItem
                    key={opponent?.id}
                    avatar={opponent?.avatar}
                    name={opponent?.name}
                    unreadCount={unread_count}
                    text={rest?.last_message?.text}
                    sentAt={convertedSentAt}
                    lastSeen={opponent?.last_seen}
                    onClick={onItemClick}
                    {...rest?.last_message}
                    isTyping={isTyping}
                    id={opponent?.id}
                  />
                );
              },
            )}
          </Grid>
        )}

        {!isMobile && (
          <Grid
            item
            md={8}
            xs={0}
            sx={{
              borderLeft: '1px solid lightgrey',
              '@media screen and (min-width: 1024px)': {
                height: '100%',
                overflow: 'auto',
              },
            }}
          >
            {searchParams.get('chatId') ? (
              <DirectMessage />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 500,
                }}
              >
                Select a chat to start messaging
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
