import { LAST_SEEN_VALUES } from 'components/avatar/Avatar';
import { Events } from 'web-socket';
import { updateChats } from 'web-socket/utils/update-chats';

export const handleUserStatus = ({ updateCachedData, parsedData }) => {
  if (
    parsedData?.type === Events.UserIsOnline
    || parsedData?.type === Events.UserIsOffline
  ) {
    const userId = parsedData?.user_id;
    const lastSeen = parsedData?.type === Events.UserIsOnline
      ? LAST_SEEN_VALUES.NOW
      : parsedData?.last_seen;

    updateCachedData((draft) => {
      const chats = draft.data.list;
      const idxOfOnlineChat = chats.findIndex((chat) => chat?.opponent?.id === userId);

      const isOnlineLastSeenNotNow = parsedData?.type === Events.UserIsOnline
        && chats[idxOfOnlineChat]?.opponent.last_seen !== LAST_SEEN_VALUES.NOW;

      const isOfflineLastSeenNowOrDifferent = parsedData?.type === Events.UserIsOffline
        && (chats[idxOfOnlineChat]?.opponent.last_seen === LAST_SEEN_VALUES.NOW
          || chats[idxOfOnlineChat]?.opponent.last_seen !== lastSeen);

      if (
        idxOfOnlineChat !== -1
        && (isOnlineLastSeenNotNow || isOfflineLastSeenNowOrDifferent)
      ) {
        // eslint-disable-next-line no-param-reassign
        draft.data.list = updateChats({
          chats,
          opponentId: userId,
          fieldsToUpdate: {
            opponent: {
              ...chats[idxOfOnlineChat].opponent,
              last_seen: lastSeen,
            },
          },
        });
      }
    });
  }
};
