export const styles = {
  textField: {
    borderRadius: '8px',
    backgroundColor: '#F7F7F7',
    border: 'none',
    width: '100%',

    '& .MuiInputBase-root': {
      borderRadius: '8px',
      padding: '13px 20px',
      width: '100%',
      height: '50px',
    },
    '& .MuiInputBase-root::before, .MuiInputBase-root::after': {
      content: 'none',
    },
    '&:focus, &:active': {
      border: 'none',
      outline: 'none',
    },
    '& .Mui-focused': {
      backgroundColor: 'rgba(194, 193, 218, 0.2)',
    },
  },
  btn: {
    backgroundColor: '#3080ED',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '19px 0',
    fontWeight: 700,
    color: '#fff',
    fontSize: 17,
    borderRadius: '10px',
    height: '60px',
    marginTop: '30px',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#3080ED',
    },
    '&.Mui-disabled': {
      color: '#fff',

      backgroundColor: '#eee',
    },
  },
};
