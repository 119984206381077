import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { useReadMessage } from 'hooks/useReadMessage';
import { isMobile } from 'components/app-layout/AppLayout';
import { MessagesList } from './MessagesList';

export const Messages = ({
  data = [],
  opponentData = {},
  activeChatData,
  id,
  fetchMoreData,
  isLoading,
  messagesContainerRef,
  messageActionsContainerRef,
  handleSend,
}) => {
  const [forceUpdate, setForceUpdate] = useState(false);
  const [actionsHeight, setActionsHeight] = useState(
    messageActionsContainerRef?.current?.clientHeight,
  );
  const { readMessage } = useReadMessage();

  const scrollToBottom = () => {
    // eslint-disable-next-line no-param-reassign
    messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
  };

  const unreadCount = activeChatData?.unread_count;

  const handleReadMessage = useCallback(
    (unreadMessagesCount) => {
      const shouldReadMessage = messagesContainerRef.current.scrollTop === 0 && Boolean(unreadMessagesCount);

      if (shouldReadMessage) {
        const { chat_id, id: messageId } = data[0];

        readMessage({
          chat_id,
          unread_count: unreadMessagesCount,
          opponentId: id,
          message_id: messageId,
        });
      }
    },
    [data, readMessage, id, unreadCount],
  );

  useEffect(() => {
    if (!forceUpdate) {
      setTimeout(() => setForceUpdate(true));
    }
  }, []);

  useEffect(() => {
    if (messagesContainerRef.current.scrollTop === 0 && Boolean(unreadCount)) {
      handleReadMessage(unreadCount);
    }
  }, [unreadCount, messagesContainerRef, handleReadMessage]);

  useEffect(() => {
    if (!messageActionsContainerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setActionsHeight(messageActionsContainerRef.current?.clientHeight);
    });
    resizeObserver.observe(messageActionsContainerRef.current);
    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    }; // clean up
  }, []);

  useEffect(() => {
    if (data.length) {
      scrollToBottom();
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#E5E5E5',
        padding: '10px 0',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse',
        marginTop: isMobile ? '71px' : 0, // height of top bar
        marginBottom: `${actionsHeight}px`, // height of message bar
        height: isMobile ? 'auto' : '100%',
        '@media screen and (min-width: 1024px)': {
          marginBottom: 0,
        },
      }}
      id="scrollableDiv"
      ref={messagesContainerRef}
    >
      <MessagesList
        data={data}
        opponentData={opponentData}
        id={id}
        fetchMoreData={fetchMoreData}
        isLoading={isLoading}
        activeChatData={activeChatData}
        handleSend={handleSend}
      />
    </Box>
  );
};
