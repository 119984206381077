/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { LSIMTokenName, LSUser } from 'pages/login/constants';
import { tryParseJSONObject } from 'utils/tryParseJSONToObject';

const initialState = {
  IMToken: localStorage.getItem(LSIMTokenName) || null,
  user: tryParseJSONObject(localStorage.getItem(LSUser)) || null,
  loginURL: 'https://api.moonscap.com',
  imURL: 'https://messenger.api.peaxem.com',
};
console.log('test');
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIMToken: (state, { payload }) => {
      state.IMToken = payload;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setLoginURL: (state, { payload }) => {
      state.loginURL = payload;
    },
    setIMURL: (state, { payload }) => {
      state.imURL = payload;
    },
    logout: () => {},
  },
});

export const {
  setIMToken, setUser, logout, setLoginURL, setIMURL,
} = authSlice.actions;

const selectAuth = (state) => state.auth;

export const getToken = createSelector(selectAuth, (auth) => auth.IMToken);
export const getUser = createSelector(selectAuth, (auth) => auth.user);
export const getLoginURL = createSelector(selectAuth, (auth) => auth.loginURL);
export const getIMURL = createSelector(selectAuth, (auth) => auth.imURL);

export default authSlice.reducer;
