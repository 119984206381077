import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Box, Switch, Typography, Stack,
} from '@mui/material';

import { Controller, useWatch } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSetAutoAnswerMutation } from 'services/api';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const Switcher = ({ control, setValue }) => {
  const [setAutoAnswer] = useSetAutoAnswerMutation();

  const [error, setError] = useState('');
  const quickMessages = useWatch({ control, name: 'quickMessages' });
  const isQuickMessagesChoosen = quickMessages?.some(({ checked }) => checked);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '32px',
        paddingLeft: '32px',
        marginTop: '32px',
      }}
    >
      发送当前位置
      <Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>
            关
            {/* Off */}
          </Typography>
          <Controller
            control={control}
            name="autoAnswer"
            render={({ field }) => (
              <AntSwitch
                {...field}
                checked={field.value}
                onChange={(event) => {
                  if (!isQuickMessagesChoosen) {
                    setError('Please choose at least 1 quick message');
                  } else {
                    if (error.length) {
                      setError('');
                    }
                    const autoAnswerId = quickMessages.find(({ checked }) => checked).id;

                    field.onChange(event);

                    if (!event.target.checked) {
                      setValue(
                        'quickMessages',
                        quickMessages.map((item) => ({ ...item, checked: false })),
                      );
                    }

                    setAutoAnswer({
                      id: event.target.checked ? autoAnswerId : null,
                    })
                      .then(({ data: { msg } }) => {
                        toast.success(msg);
                      })
                      .catch(({ data }) => {
                        toast.error(`Something went wrong: ${JSON.stringify(data)}`);
                      });
                  }
                }}
              />
            )}
          />

          <Typography>
            开
            {/* On */}
          </Typography>
        </Stack>
      </Box>
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};
