import { Loader } from 'components/loader/Loader';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useGetChatsQuery } from 'services/api';

import { ProfileSkeleton } from './ProfileSkeleton';

export const UserProfile = () => {
  const { id } = useParams();

  const { data: chatsData, isLoading } = useGetChatsQuery();

  const activeChatData = chatsData?.data?.list.find(
    ({ id: userId }) => Number(userId) === Number(id),
  );

  useEffect(() => {
    document.title = '个人信息';
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <ProfileSkeleton
      id={id}
      name={activeChatData?.opponent.name}
      avatar={`https://static.moonscap.com${activeChatData?.opponent.avatar}`}
    />
  );
};
