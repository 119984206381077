/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import {
  Box, Button, Modal, Popover,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { api, useDeleteChatsMutation } from 'services/api';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { isMobile } from 'components/app-layout/AppLayout';
import { Avatar } from '../../components/avatar/Avatar';

import { styles } from './styles';
import { VideoPreview } from './components/VideoPreview';
import { ImagePreview } from './components/ImagePreview';
import { MediaPreview } from './components/MediaPreview';
import { AnimationPreview } from './components/AnimationPreview';

export const ChatItem = ({
  id,
  avatar,
  unreadCount,
  name,
  sentAt,
  lastSeen,
  onClick,
  isTyping = false,
  text = null,
  video = null,
  photo = null,
  media = null,
  animation = null,
  sticker = null,
  service_text = null,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSwiped, setIsSwiped] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const handlers = useSwipeable({
    onSwipedLeft: () => setIsSwiped(true),
    onSwipedRight: () => setIsSwiped(false),
  });

  const dispatch = useDispatch();
  const [deletChat] = useDeleteChatsMutation();
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        ...styles.chatItem,
        backgroundColor:
          Number(searchParams.get('chatId')) === id ? 'rgba(47, 128, 237, 0.2)' : '#fff',
      }}
      {...handlers}
      onContextMenu={(e) => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
        }}
        onClick={onClick}
      >
        <Avatar lastSeen={lastSeen} avatar={avatar} />
        <Box sx={styles.mainTextWrapper}>
          <Box sx={styles.textWrapper}>
            <Box sx={styles.nickname} title={name}>
              {name}
            </Box>
            <Box sx={styles.date}>{sentAt}</Box>
          </Box>
          <Box sx={styles.textWrapper}>
            {isTyping ? (
              <Box sx={{ fontSize: 12, color: '#4F5E7B' }}>typing...</Box>
            ) : (
              <>
                {' '}
                {(text || sticker || service_text) && (
                  <Box sx={styles.message}>{text || sticker || service_text}</Box>
                )}
                {video && <VideoPreview {...video} />}
                {photo && <ImagePreview {...photo} />}
                {media && <MediaPreview {...media} />}
                {animation && <AnimationPreview />}
              </>
            )}

            {Boolean(unreadCount) && <Box sx={styles.unreadCounter}>{unreadCount}</Box>}
          </Box>
        </Box>
      </Box>
      {isSwiped && (
        <Box
          sx={{
            width: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => setOpen(true)}
        >
          <DeleteIcon
            sx={{
              fill: '#FF8484',
            }}
          />
        </Box>
      )}

      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Button
          variant="text"
          startIcon={(
            <DeleteIcon
              sx={{
                fill: '#FF8484',
              }}
            />
          )}
          onClick={() => {
            setAnchorEl(null);
            setOpen(true);
          }}
          sx={{
            textTransform: 'none',
            color: '#FF8484',
            '& .MuiButton-startIcon': {
              marginRight: '16px',
            },
          }}
        >
          Delete chat
        </Button>
      </Popover>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0, 0.7)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            alignItems: 'center',
            padding: '16px',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            Delete chat with
            {' '}
            {name}
            {' '}
            ?
          </Box>
          <Box marginTop="16px">
            <Button type="contained" onClick={() => setOpen(false)}>
              Cancel
            </Button>

            <Button
              type="contained"
              sx={{
                backgroundColor: '#FF8484',
                color: '#fff',
                marginLeft: '16px',
                '&:hover': {
                  backgroundColor: '#FF8484',
                },
              }}
              onClick={() => {
                deletChat({ chat_ids: [id] }).then(({ data }) => {
                  if (data.code === 0) {
                    dispatch(
                      api.util.updateQueryData('getChats', undefined, (draft) => {
                        const chats = draft.data.list;
                        const idxOfChat = chats.findIndex(
                          (chat) => chat?.opponent?.id === id,
                        );

                        if (idxOfChat !== -1) {
                          const copiedChats = [...chats];
                          copiedChats.splice(idxOfChat, 1);

                          draft.data.list = copiedChats;
                        }
                      }),
                    );
                    dispatch(
                      api.util.updateQueryData(
                        'getMessages',
                        { chatId: String(id), page: 1, limit: 10 },
                        (draft) => {
                          draft.data.list = [];
                        },
                      ),
                    );
                    setOpen(false);
                    if (!isMobile) {
                      setSearchParams({});
                    }
                  }
                });
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
