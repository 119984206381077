import { Box, IconButton } from '@mui/material';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { useGetAnswersQuery, useGetAutoAnswerQuery } from 'services/api';
import { Loader } from 'components/loader/Loader';
import { QuickMessagesFormInput } from './QuickMessageFormInput';
import { QuickMessagesFormCheckbox } from './QuickMessageFormCheckbox';
import { Switcher } from './Switcher';

export const QuickMessagesForm = () => {
  const { data, isSuccess } = useGetAnswersQuery();
  const { data: autoAnswerData, isSuccess: isAutoAnswerSuccess } = useGetAutoAnswerQuery();

  if (!isSuccess || !isAutoAnswerSuccess) {
    return <Loader />;
  }

  const autoAnswerId = autoAnswerData.data;

  return (
    <QuickMessagesFormView
      quickMessages={
        data?.data.map((item) => ({
          ...item,
          checked: item.id === autoAnswerId,
        })) || []
      }
      autoAnswer={Boolean(autoAnswerId)}
    />
  );
};

const QuickMessagesFormView = ({ quickMessages, autoAnswer }) => {
  const { control, setValue } = useForm({
    defaultValues: {
      quickMessages,
      autoAnswer,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'quickMessages',
    keyName: 'customId',
  });

  return (
    <>
      <Switcher control={control} setValue={setValue} />

      <Box
        sx={{
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        {fields.map((field, index) => {
          return (
            <Box
              key={field.customId}
              sx={{
                display: 'flex',
                gap: '16px',
              }}
            >
              <QuickMessagesFormCheckbox
                id={field.id}
                control={control}
                index={index}
                setValue={setValue}
              />
              <QuickMessagesFormInput
                control={control}
                index={index}
                remove={() => remove(index)}
                id={field.id}
                setValue={setValue}
              />
            </Box>
          );
        })}

        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            fontSize: '14px',
            marginTop: '24px',
          }}
        >
          <IconButton
            onClick={() => {
              append({
                text: '',
                checked: false,
              });
            }}
            color="primary"
            sx={{
              borderRadius: '2px',
              border: '1px solid #467EE5',
              width: 24,
              height: 24,
              '&.Mui-disabled': {
                border: '1px solid rgba(0, 0, 0, 0.26)',
              },
            }}
            disabled={fields.length === 5}
          >
            <AddIcon />
          </IconButton>
          发送当前位置
        </Box>
      </Box>
    </>
  );
};
