import React from 'react';
import { Box, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Back } from 'pages/direct-message/assets/arrow-back.svg';
import { BottomMenu } from 'components/bottom-menu/BottomMenu';

import { Sidebar } from 'components/sidebar/Sidebar';
import { styles } from './styles';

export const isMobile = window.innerWidth <= 1024;

export const AppLayout = ({ children, title, showBackBtn = false }) => {
  const navigate = useNavigate();

  const location = useLocation();

  const isBottomMenuHidden = location.pathname.includes('direct-message');

  return (
    <Box sx={styles.container}>
      {showBackBtn && (
        <Box
          sx={{
            position: 'relative',
            backgroundColor: '#fff',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '15px',
            fontSize: 18,
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <Back />
          </IconButton>

          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {title}
          </Box>
        </Box>
      )}
      <Sidebar />

      {children}

      {!isBottomMenuHidden && <BottomMenu />}
    </Box>
  );
};
