import { Grid, Typography } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

import React from 'react';
import { ReactComponent as Profile } from './assets/profile.svg';

import { appRoutes } from '../../routes/appRoutes';

export const ProfileMenuItem = ({ onClick }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      sx={{
        width: 90,
        '& svg path': {
          fill: location.pathname === appRoutes.MY_PROFILE ? '#2F80ED' : '#4F5E7B',
        },
        cursor: 'pointer',
      }}
      onClick={() => {
        navigate(appRoutes.MY_PROFILE);
        onClick?.();
      }}
    >
      <Profile />
      <Typography
        sx={{
          color: location.pathname === appRoutes.MY_PROFILE ? '#2F80ED' : '#4F5E7B',
          fontSize: 12,
        }}
      >
        我的
      </Typography>
    </Grid>
  );
};
