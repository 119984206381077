/* eslint-disable react/button-has-type */
import { CircularProgress, Box } from '@mui/material';

import React from 'react';

export const SubmitButton = ({
  className,
  buttonClassName,
  style,
  buttonStyle,
  disabled,
  content,
  onSubmit,
  files,
}) => {
  // eslint-disable-next-line no-underscore-dangle
  const _disabled = files.some((f) => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status)) || !files.some((f) => ['headers_received', 'done'].includes(f.meta.status));

  const handleSubmit = () => {
    onSubmit(files.filter((f) => ['headers_received', 'done'].includes(f.meta.status)));
  };

  return (
    <div className={className} style={style}>
      <button
        className={buttonClassName}
        style={buttonStyle}
        onClick={handleSubmit}
        disabled={disabled || _disabled}
      >
        {_disabled ? (
          <Box width="50px">
            <CircularProgress
              sx={{
                color: '#fff',
              }}
              size={15}
            />
          </Box>
        ) : (
          content
        )}
      </button>
    </div>
  );
};
