export const getQuickMessagesApi = (builder) => ({
  getAnswers: builder.query({
    query: () => '/getAnswers',
    keepUnusedDataFor: 0,
  }),
  createAnswer: builder.mutation({
    query: ({ text }) => ({
      url: '/createAnswer',
      method: 'POST',
      body: { text },
    }),
  }),
  editAnswer: builder.mutation({
    query: ({ text, id }) => ({
      url: '/editAnswer',
      method: 'POST',
      body: { text, id },
    }),
  }),
  deleteAnswer: builder.mutation({
    query: ({ id }) => ({
      url: '/deleteAnswer',
      method: 'POST',
      body: { id },
    }),
  }),
  setAutoAnswer: builder.mutation({
    query: ({ id }) => ({
      url: '/setAutoAnswer',
      method: 'POST',
      body: { id },
    }),
  }),
  getAutoAnswer: builder.query({
    query: () => '/getAutoAnswer',
    keepUnusedDataFor: 0,
  }),
});
