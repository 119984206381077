import { Grid } from '@mui/material';

import React from 'react';

import { HomePageMenuItem } from './HomePageMenuItem';
import { FriendsMenuItem } from './FriendsMenuItem';
import { ProfileMenuItem } from './ProfileMenuItem';

export const BottomMenu = () => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: '#FFF',
        height: 80,
        paddingLeft: '20px',
        paddingRight: '20px',
        boxShadow: '0px -8px 32px rgba(70, 96, 135, 0.1)',
        position: 'fixed',
        bottom: 0,
        '@media screen and (min-width: 1024px)': {
          display: 'none',
        },
      }}
      alignItems="center"
      justifyContent="space-between"
    >
      <HomePageMenuItem />
      <FriendsMenuItem />
      <ProfileMenuItem />
    </Grid>
  );
};
