import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getLoginURL } from 'redux/auth/slice';
import { decrypt, encrypt } from 'utils';
import { tryParseJSONObject } from 'utils/tryParseJSONToObject';
import { v4 as uuidv4 } from 'uuid';

export const LOGIN_BASE_URL = 'https://api.moonscap.com';

const dynamicBaseQuery = async (args, api, extraOptions) => {
  const loginURL = getLoginURL(api.getState());

  const rawBaseQuery = fetchBaseQuery({
    baseUrl: loginURL,
  });

  return rawBaseQuery(args, api, extraOptions);
};

export const login = createApi({
  reducerPath: 'login',
  baseQuery: dynamicBaseQuery,
  keepUnusedDataFor: 1000000000,
  tagTypes: ['Login'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({
        username, password, country_code, ...rest
      }) => {
        const device_id = uuidv4();

        const encrypted = encrypt(
          JSON.stringify({
            ver: '3.11.5',
            uuid: uuidv4(),
            plat: 'android',
            pver: '15.2',
            issued: 'pusher',
            lob: 1,
            emulator: 0,
            dist: 0,
            rid: 0,
            nonce: '4aq871z9',
          }),
        );

        return {
          url: '/OpenAPI/v1/user/login',
          method: 'POST',
          body: {
            username, password, country_code, device_id, ...rest,
          },
          headers: {
            'X-Live-Butter2': encrypted,
          },
          responseHandler: async (response) => {
            const responseText = await response.text();

            return tryParseJSONObject(responseText) || decrypt(responseText);
          },
        };
      },
    }),
    getCaptcha: builder.query({
      query: () => '/OpenAPI/v1/user/get_login_captcha',
    }),
    loginIM: builder.mutation({
      query: ({ token }) => {
        return {
          url: '/OpenAPI/v2/im/login',
          method: 'POST',
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      },
    }),
  }),
});

export const { useLoginMutation, useGetCaptchaQuery, useLoginIMMutation } = login;
