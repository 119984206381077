import React, { useState } from 'react';

import {
  Drawer, Grid, Box, IconButton, Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { HomePageMenuItem } from 'components/bottom-menu/HomePageMenuItem';
import { FriendsMenuItem } from 'components/bottom-menu/FriendsMenuItem';
import { ReactComponent as Copy } from 'pages/profile/copy.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setIMToken, setUser } from 'redux/auth/slice';
import { Avatar } from 'components/avatar/Avatar';
import { appRoutes } from 'routes/appRoutes';
import { api } from 'services/api';
import { LSIMTokenName, LSUser } from 'pages/login/constants';
import { useNavigate } from 'react-router-dom';
import { QuickMessageMenuMenuItem } from 'components/bottom-menu/QuickMessageMenuItem';

export const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const onClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(api.util.resetApiState());
    localStorage.removeItem(LSUser);
    localStorage.removeItem(LSIMTokenName);
    dispatch(setIMToken(null));
    dispatch(setUser(null));
    if (window.IMSocket) {
      window.IMSocket.close();
    }

    navigate(appRoutes.LOGIN);
    window.location.reload();
  };

  return (
    <Box
      sx={{
        display: 'none',
        '@media screen and (min-width: 1024px)': {
          display: 'block',
        },
      }}
    >
      <IconButton onClick={() => setOpen(true)} sx={{ marginLeft: '20px' }}>
        <MenuIcon />
      </IconButton>
      <Drawer
        open={open}
        anchor="left"
        onClose={onClose}
        sx={{
          '& .MuiPaper-root': {
            display: 'flex',
            alignItems: 'center',
            minWidth: 120,
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          justifyContent="center"
          marginTop="16px"
        >
          <Avatar
            avatar={user?.avatar}
            avatarWrapperSx={{
              width: 50,
              marginRight: 0,
            }}
          />
          <Box
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              maxWidth: 90,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              marginTop: '5px',
            }}
            title={user?.nickname}
          >
            {user?.nickname}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 12,
              color: '#4F5E7B',
            }}
            onClick={() => {
              navigator.clipboard.writeText(user?.id);
            }}
          >
            <Box marginRight="5px">
              ID:
              {user?.id}
            </Box>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(user?.id);
              }}
            >
              <Copy />
            </IconButton>
          </Box>
        </Box>
        <Grid container mt={4} flexDirection="column" gap={6} alignItems="center">
          <HomePageMenuItem onClick={onClose} />
          <FriendsMenuItem onClick={onClose} />
          <QuickMessageMenuMenuItem onClick={onClose} />
        </Grid>

        <Button
          sx={{
            backgroundColor: '#3080ED',
            width: 'calc(100% - 10px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px 10px',
            fontWeight: 700,
            color: '#fff',
            fontSize: 14,
            borderRadius: '10px',

            margin: '100px 10px',
            '&:hover, &:active, &:focus': {
              backgroundColor: '#3080ED',
            },
          }}
          onClick={handleLogout}
        >
          退出登陆
        </Button>
      </Drawer>
    </Box>
  );
};
