import React from 'react';
import { useSelector } from 'react-redux';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';

import { getToken } from 'redux/auth/slice';
import { SocketWrapper } from 'web-socket/SocketWrapper';

import { privateRoutesConfig, publicRoutesConfig } from './appRoutes';
import { PrivateRoute } from './PrivateRoute';

const privateRoutes = privateRoutesConfig.map(({ path, Element }) => (
  <Route key={path} path={path} element={<Element />} />
));

const publicRoutes = publicRoutesConfig.map(({ path, Element }) => (
  <Route key={path} path={path} element={<Element />} />
));

export const Routes = () => {
  const isAuthenticated = useSelector(getToken);

  return (
    <Switch>
      <Route
        exact
        path="/"
        element={(
          <SocketWrapper>
            <PrivateRoute isAuthenticated={isAuthenticated} />
          </SocketWrapper>
        )}
      >
        {privateRoutes}
      </Route>

      {publicRoutes}

      <Route path="*" element={<Navigate to="/" replace />} />
    </Switch>
  );
};
