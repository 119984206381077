/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { setIMURL, setLoginURL } from 'redux/auth/slice';
import isReachable from 'is-reachable';

import { Box, CircularProgress } from '@mui/material';
import { getFilteredAnswers } from 'utils/getFilteredAnswers';
import { Routes } from './routes';

const CNURLs = [
  'https://1.1.1.1/dns-query?ct=application/dns-json&name=_sd._tls.api.moonscap.com&type=SRV',
  'https://1.0.0.1/dns-query?ct=application/dns-json&name=_sd._tls.api.moonscap.com&type=SRV',
  'https://cloudflare-dns.com/dns-query?ct=application/dns-json&name=_sd._tls.api.moonscap.com&type=SRV',
  'https://223.5.5.5/resolve?name=_sd._tls.api.moonscap.com&type=33',
  'https://doh.pub/dns-query?name=_sd._tls.api.moonscap.com&type=33',
];

const IMCNURLs = [
  'https://1.1.1.1/dns-query?ct=application/dns-json&name=_messager._tls.api.moonscap.com&type=SRV',
  'https://1.0.0.1/dns-query?ct=application/dns-json&name=_messager._tls.api.moonscap.com&type=SRV',
  'https://cloudflare-dns.com/dns-query?ct=application/dns-json&name=_messager._tls.api.moonscap.com&type=SRV',
  'https://223.5.5.5/resolve?name=_messager._tls.api.moonscap.com&type=33',
  'https://doh.pub/dns-query?name=_messager._tls.api.moonscap.com&type=33',
];

const getReachableHost = (hosts, idx) => {
  return isReachable(hosts[idx]?.pingURL)
    .then((reachable) => {
      return {
        url: hosts[idx]?.originalURL,
        reachable,
      };
    })
    .catch(() => {
      return getReachableHost(hosts[idx], idx + 1);
    });
};

export const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    function getFirstValidAPIURL({
      arr,
      index,
      setURL,
      originalArray,
      originalIndex,
      config = { headers: { withCredentials: false, Accept: 'application/dns-json' } },
      isLogin = true,
    }) {
      return axios
        .get(arr[index], config)
        .then((response) => {
          const isCorrectHost = response?.data?.data === 'pong';

          if (isCorrectHost) {
            const urlToSet = arr[index] && new URL(arr[index]);

            dispatch(setURL(urlToSet?.origin));
            setIsLoading(false);
          } else {
            // eslint-disable-next-line no-lonely-if
            if (index < arr.length - 1) {
              getFirstValidAPIURL({
                arr,
                index,
                config,
                setURL,
                isLogin,
              });
            }
          }
        })
        .catch(() => {
          if (originalIndex < originalArray.length) {
            return getFirstValid({
              arr: originalArray,
              index: originalIndex + 1,
              config,
              setURL,
              isLogin,
            });
          }

          dispatch(setLoginURL('https://api.moonscap.com'));
          dispatch(setIMURL('https://messenger.api.peaxem.com'));
          window.IMSocket = new WebSocket('wss://messenger.api.peaxem.com');
        });
    }
    function getFirstValid({
      arr,
      index,
      setURL,
      config = { headers: { withCredentials: false, Accept: 'application/dns-json' } },
      isLogin = true,
    }) {
      return axios
        .get(arr[index], config)
        .then((response) => {
          const isCorrectHost = response?.data?.data === 'pong';
          const answers = response?.data?.Answer;

          if (isCorrectHost) {
            const urlToSet = arr[index] && new URL(arr[index]);

            dispatch(setURL(urlToSet?.origin));
            setIsLoading(false);
          }
          if (!answers && !isCorrectHost) {
            getFirstValid(arr, index + 1, setURL, config, isLogin);
          }
          if (answers) {
            const filteredAnswers = getFilteredAnswers({ answers, isLogin });

            getFirstValidAPIURL({
              arr: filteredAnswers.map(({ pingURL }) => pingURL),
              index: 0,
              setURL,
              originalArray: arr,
              originalIndex: index,
              config,
              isLogin,
            });
          }
        })
        .catch((e) => {
          if (index < arr.length) {
            return getFirstValid({
              arr,
              index: index + 1,
              config,
              setURL,
              isLogin,
            });
          }

          dispatch(setLoginURL('https://api.moonscap.com'));
          dispatch(setIMURL('https://messenger.api.peaxem.com'));
          window.IMSocket = new WebSocket('wss://messenger.api.peaxem.com');

          throw e;
        });
    }

    getReachableHost([{ pingURL: 'https://plqqqay.google.com' }], 0)
      .then(({ reachable }) => {
        if (reachable) {
          setIsLoading(false);
          dispatch(setLoginURL('https://api.moonscap.com'));
          dispatch(setIMURL('https://messenger.api.peaxem.com'));
          window.IMSocket = new WebSocket('wss://messenger.api.peaxem.com');
        } else {
          getFirstValid({
            arr: CNURLs,
            index: 0,
            config: { headers: { Accept: 'application/dns-json' } },
            setURL: setLoginURL,
          });
          getFirstValid({
            arr: IMCNURLs,
            index: 0,
            config: { headers: { Accept: 'application/dns-json' } },
            setURL: setIMURL,
            isLogin: false,
          });
        }
      })
      .catch(() => {
        getFirstValid({
          arr: CNURLs,
          index: 0,
          config: { headers: { Accept: 'application/dns-json' } },
          setURL: setLoginURL,
        });
        getFirstValid({
          arr: IMCNURLs,
          index: 0,
          config: { headers: { Accept: 'application/dns-json' } },
          setURL: setIMURL,
          isLogin: false,
        });
      });
  }, []);

  useEffect(() => {
    if (Notification) {
      Notification.requestPermission();
    }
  }, []);

  if (isLoading) {
    return (
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return <Routes />;
};
