import { Grid, Typography } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

import React from 'react';
import { ReactComponent as Chat } from './assets/chat.svg';

import { appRoutes } from '../../routes/appRoutes';

export const HomePageMenuItem = ({ onClick }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      sx={{
        cursor: 'pointer',
        width: 90,
        '& svg path': {
          fill:
            location.pathname === appRoutes.HOME_PAGE ? '#2F80ED' : 'rgb(79, 94, 123)',
        },
      }}
      onClick={() => {
        navigate(appRoutes.HOME_PAGE);
        onClick?.();
      }}
    >
      <Chat />
      <Typography
        sx={{
          color:
            location.pathname === appRoutes.HOME_PAGE ? '#2F80ED' : 'rgb(79, 94, 123)',
          fontSize: 12,
        }}
      >
        聊天
      </Typography>
    </Grid>
  );
};
