import { configureStore } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { login } from 'services/login';
import authReducer from './auth/slice';
import messagesReducer from './messages/slice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [login.reducerPath]: login.reducer,
    auth: authReducer,
    messages: messagesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware).concat(login.middleware),
});
