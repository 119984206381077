import {
  Grid,
  IconButton,
  TextField,
  Box,
  CircularProgress,
  Button,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Events } from 'web-socket';
import { FileUploader } from 'components/file-uploader/FileUploader';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { getUser } from 'redux/auth/slice';
import { ReactComponent as ShowTemplates } from 'assets/show-templates.svg';
import { QuickMessageItem } from './QuickMessageItem';
import { ReactComponent as Send } from '../../assets/send.svg';

export const MessageActions = ({
  chatId,
  limit,
  handleSend,
  text,
  setText,
  messageActionsContainerRef,
  isAutoAnswersSuccess,
  autoAnswers,
}) => {
  const [isTemplatesOpen, setIsTemplatesOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const user = useSelector(getUser);

  const onKeyDown = (e) => {
    if (
      !window.IMTimer
      && window.IMSocket
      && window.IMSocket.readyState === WebSocket.OPEN
    ) {
      window.IMSocket.send(
        JSON.stringify({ type: Events.SendStartTyping, chat_id: chatId }),
      );
    }
    clearTimeout(window.IMTimer);

    window.IMTimer = setTimeout(() => {
      if (window.IMSocket && window.IMSocket.readyState === WebSocket.OPEN) {
        window.IMSocket.send(
          JSON.stringify({ type: Events.SendEndTyping, chat_id: chatId }),
        );
      }
      window.IMTimer = null;
    }, 3000);

    if (e.key === 'Enter' && !e.shiftKey && text.trim().length) {
      e.preventDefault();
      handleSend(text);
    }
  };

  const onTemplatesBtnClick = () => {
    setIsTemplatesOpen((prevState) => !prevState);
  };

  const onBlur = () => {
    clearTimeout(window.IMTimer);
    if (
      window.IMTimer
      && window.IMSocket
      && window.IMSocket.readyState === WebSocket.OPEN
    ) {
      window.IMSocket.send(
        JSON.stringify({ type: Events.SendEndTyping, chat_id: chatId }),
      );
      window.IMTimer = null;
    }
  };

  useEffect(() => {
    const onRestartPage = () => {
      if (window.IMTimer) {
        window.IMSocket.send(
          JSON.stringify({ type: Events.SendEndTyping, chat_id: chatId }),
        );
      }
    };

    window.addEventListener('beforeunload', onRestartPage);

    return () => {
      window.removeEventListener('beforeunload', onRestartPage);
    };
  }, []);

  return (
    <Grid
      ref={messageActionsContainerRef}
      container
      sx={{
        backgroundColor: '#FFF',
        height: 'auto',
        position: 'fixed',
        bottom: 0,
        paddingLeft: '10px',
        paddingRight: '10px',
        boxShadow: '0px -8px 32px rgba(70, 96, 135, 0.1)',
        '@media screen and (min-width: 1024px)': {
          position: 'static',
        },
      }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={12} display="flex" alignItems="flex-end">
        <Box
          width="30px"
          height="50px"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            '& svg': {
              fill: '#2F80ED',
            },
            '@media screen and (min-width: 1024px)': {
              display: 'none',
            },
          }}
        >
          <ShowTemplates onClick={onTemplatesBtnClick} />
        </Box>
        <Box
          width="30px"
          height="50px"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            '& svg': {
              fill: '#2F80ED',
            },
          }}
        >
          <AttachFileIcon
            onClick={() => {
              setOpen(true);
              setTimeout(() => {
                const input = document.querySelector('.dzu-input');
                input.click();
              }, 100);
            }}
          />
        </Box>
        {open && (
          <FileUploader
            open={open}
            setOpen={setOpen}
            chatId={chatId}
            user={user}
            limit={limit}
          />
        )}
        <TextField
          inputRef={(input) => {
            // eslint-disable-next-line no-unused-expressions
            input && input.focus();
          }}
          value={text}
          onChange={(e) => setText(e.target.value)}
          name="test"
          placeholder="请输入您的消息......"
          variant="standard"
          multiline
          autoFocus
          maxRows={5}
          sx={{
            height: '100%',
            padding: '10px 0',
            width: 'calc(100% - 40px)',
            '& .MuiInputBase-root': {
              alignItems: 'flex-start',
              '&::after': {
                content: 'none',
              },
              '&::before': {
                content: 'none',
              },
            },
          }}
          InputProps={{
            autoFocus: true,
            endAdornment: isAutoAnswersSuccess ? (
              <Box
                marginRight={1}
                sx={{
                  gap: '8px',
                  flexWrap: 'wrap',
                  width: '100%',
                  maxWidth: '500px',
                  justifyContent: 'flex-end',
                  display: 'none',
                  '@media screen and (min-width: 1024px)': {
                    flexDirection: 'row',
                    display: 'flex',
                  },
                }}
              >
                {autoAnswers.map((item) => (
                  <QuickMessageItem
                    key={item.id}
                    text={item.text}
                    onClick={() => {
                      handleSend(item.text);
                    }}
                  />
                ))}
              </Box>
            ) : (
              <CircularProgress size={23} sx={{ marginRight: 1 }} />
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            sx: {
              fontSize: 12,
              border: 'none',
              '&::placeholder': {
                fontSize: 12,
              },
            },
          }}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
        <IconButton
          sx={{
            backgroundColor: '#2F80ED',
            marginBottom: '7px',
            '&:hover, &:active, &:focus': {
              backgroundColor: '#2F80ED',
            },
            '&.Mui-disabled': {
              backgroundColor: 'lightgray',
            },
          }}
          onClick={() => handleSend(text)}
          disabled={!text.length}
        >
          <Send />
        </IconButton>
      </Grid>
      {isTemplatesOpen && isAutoAnswersSuccess && (
        <Box
          width="100%"
          sx={{
            maxHeight: 150,
            overflowY: 'auto',
            '@media screen and (min-width: 1024px)': {
              display: 'none',
            },
          }}
        >
          {autoAnswers.map((answer) => {
            const onButtonClick = () => {
              handleSend(answer.text);
            };
            return (
              <Box
                key={answer.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <Typography
                  sx={{
                    color: '#8D8CAB',
                    fontSize: '12px',
                    display: 'block',
                    marginRight: '10px',
                    whiteSpace: 'nowrap',
                    maxWidth: '340px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingRight: '10px',
                  }}
                  onClick={() => {
                    setText(answer.text);
                  }}
                >
                  {answer.text}
                </Typography>
                <Button
                  sx={{
                    backgroundColor: '#3080ED',
                    width: '50px',
                    minWidth: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '4px 10px',
                    fontWeight: 400,
                    color: '#fff',
                    fontSize: 10,
                    borderRadius: '10px',
                    height: '23px',
                    '&:hover, &:active, &:focus': {
                      backgroundColor: '#3080ED',
                    },
                  }}
                  onClick={onButtonClick}
                >
                  发送
                </Button>
              </Box>
            );
          })}
        </Box>
      )}
    </Grid>
  );
};
