/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Box, ImageList, ImageListItem } from '@mui/material';
import React from 'react';

import { ImageWithPreview } from './ImageWithPreview';
import { VideoWithPreview } from './VideoWithPreview';

export const Media = ({ items, caption = '', captionColor = '#1B1A57' }) => {
  return (
    <>
      <ImageList sx={{ width: '100%', height: '100%' }} cols={3} rowHeight={164}>
        {items.map(({ photo, video }) => {
          if (photo) {
            return (
              <ImageListItem
                key={photo.url}
                sx={{
                  overflow: 'hidden',
                }}
              >
                <ImageWithPreview {...photo} />
              </ImageListItem>
            );
          }

          if (video) {
            return (
              <ImageListItem
                key={video.url}
                sx={{
                  overflow: 'hidden',
                }}
              >
                <VideoWithPreview {...video} />
              </ImageListItem>
            );
          }
        })}
      </ImageList>
      <Box
        sx={{
          fontSize: '12px',
          color: captionColor,
        }}
      >
        {caption}
      </Box>
    </>
  );
};
