import { Box, Popover, IconButton } from '@mui/material';
import React from 'react';
import { ReactComponent as Copy } from 'pages/profile/copy.svg';

export const UserProfilePopover = ({
  id, nickname, setAnchorEl, anchorEl,
}) => {
  const handleClose = () => setAnchorEl(null);

  return (
    <Popover
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            padding: '12px',
          }}
        >
          <Box
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              maxWidth: 90,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              marginTop: '5px',
            }}
            title={nickname}
          >
            {nickname}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 12,
              color: '#4F5E7B',
            }}
            onClick={() => {
              navigator.clipboard.writeText(id);
            }}
          >
            <Box marginRight="5px">
              ID:
              {id}
            </Box>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(id);
              }}
            >
              <Copy />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};
