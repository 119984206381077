import { AppLayout } from 'components/app-layout/AppLayout';
import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { appRoutes } from './appRoutes';

const PrivateRoute = ({ isAuthenticated }) => {
  return isAuthenticated ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : (
    <Navigate to={appRoutes.LOGIN} />
  );
};

export { PrivateRoute };
