import { Box, IconButton } from '@mui/material';
import { isMobile } from 'components/app-layout/AppLayout';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Back } from '../direct-message/assets/arrow-back.svg';
import { ReactComponent as Copy } from './copy.svg';

import { styles } from './styles';

export const ProfileSkeleton = ({ id, name, avatar }) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box sx={styles.header}>
        {isMobile && (
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <Back />
          </IconButton>
        )}

        <Box sx={styles.userInfo}>
          <Box sx={styles.avatarWrapper}>
            <img src={avatar} style={styles.avatar} alt="avatar" />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Box
              sx={{
                fontSize: '18px',
                fontWeight: 500,
              }}
            >
              {name}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 12,
                color: '#4F5E7B',
              }}
              onClick={() => {
                navigator.clipboard.writeText(id);
              }}
            >
              <Box marginRight="5px">
                ID:
                {id}
              </Box>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(id);
                }}
              >
                <Copy />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
