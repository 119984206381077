import { api } from 'services/api';
import { updateChatsToTop } from 'web-socket/utils/update-chats';

export const sendMessageEntityThenHandler = ({
  user, chatId, entityName, entityData, limit, dispatch, additionalData = null,
}) => ({ data }) => {
  const messageId = data?.data;

  const newMessage = {
    id: messageId,
    chat_id: chatId,
    sender_id: user?.id,
    sent_at: new Date().getTime() / 1000,
    [entityName]: entityData,
    ...(additionalData || {}),
  };

  dispatch(
    api.util.updateQueryData(
      'getMessages',
      { chatId: String(chatId), page: 1, limit },
      (draft) => {
        draft.data.list.unshift(newMessage);
      },
    ),
  );

  dispatch(
    api.util.updateQueryData('getChats', undefined, (draft) => {
      const chats = draft.data.list;
      const idxOfChat = chats.findIndex((chat) => chat?.opponent?.id === chatId);

      if (idxOfChat !== -1) {
        // eslint-disable-next-line no-param-reassign
        draft.data.list = updateChatsToTop({
          chats,
          chatIdToUpdate: chatId,
          fieldsToUpdate: {
            last_message: newMessage,
          },
        });
      }
    }),
  );
};
