export const Events = {
  Login: 'Login',
  Ping: 'Ping',
  LoggedInSuccessfully: 'LoggedInSuccessfully',
  MessageReceived: 'MessageReceived',
  MessageEdited: 'MessageEdited',
  UserIsOnline: 'UserIsOnline',
  UserIsOffline: 'UserIsOffline',
  UserStartedTyping: 'UserStartedTyping',
  UserEndedTyping: 'UserEndedTyping',
  SendStartTyping: 'SendStartTyping',
  SendEndTyping: 'SendEndTyping',
  MessageRead: 'MessageRead',
};
