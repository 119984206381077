export const styles = {
  avatarWrapper: {
    position: 'relative',
    height: 50,
    width: '15%',
    marginRight: '16px',
  },
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
  },
  circle: {
    position: 'absolute',
    width: 12,
    height: 12,
    border: '2px solid #fff',
    bottom: 0,
    right: 0,
    borderRadius: '100%',
  },
};
