import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {
  useCreateAnswerMutation,
  useDeleteAnswerMutation,
  useEditAnswerMutation,
  useSetAutoAnswerMutation,
} from 'services/api';
import { toast } from 'react-hot-toast';
import { QuickMessagesDeleteModal } from './QuickMessageDeleteModal';

export const QuickMessagesFormInput = ({
  control, index, remove, setValue, id,
}) => {
  const [open, setOpen] = useState(false);
  const [createAnswer] = useCreateAnswerMutation();
  const [editAnswer] = useEditAnswerMutation();
  const [deleteAnswer] = useDeleteAnswerMutation();
  const [setAutoAnswer] = useSetAutoAnswerMutation();
  const quickMessages = useWatch({ control, name: 'quickMessages' });
  const autoAnswer = useWatch({ control, name: 'autoAnswer' });

  const onDelete = () => {
    if (id) {
      const deletedItem = quickMessages.find(({ id: itemId }) => itemId === id);

      deleteAnswer({ id }).then(({ data: { msg } }) => {
        toast.success(msg);
        remove();

        if (deletedItem?.checked && autoAnswer) {
          setAutoAnswer({ id: null });
          setValue('autoAnswer', false);
        }
      });
    } else {
      remove();
    }
  };

  return (
    <>
      <Controller
        control={control}
        name={`quickMessages.${index}.text`}
        render={({ field }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '100%',
              }}
            >
              <TextField
                {...field}
                variant="outlined"
                placeholder="Add text..."
                sx={{
                  width: '100%',
                }}
                multiline
                maxRows={3}
              />

              <Box
                position="end"
                sx={{
                  display: 'flex',
                  gap: '16px',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  sx={{ borderRadius: '30px', width: 84 }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  删除
                </Button>
                <Button
                  endIcon={<BorderColorIcon />}
                  variant="contained"
                  sx={{ borderRadius: '30px' }}
                  onClick={() => {
                    if (id) {
                      editAnswer({ text: field.value, id }).then(({ data: { msg } }) => {
                        toast.success(msg);
                      });
                    } else {
                      createAnswer({ text: field.value }).then(
                        ({ data: { data: idOfItem } }) => {
                          const updatedQuickMessages = quickMessages.map((item) => {
                            if (item.text === field.value && !item.id) {
                              return {
                                id: idOfItem,
                                ...item,
                              };
                            }

                            return item;
                          });

                          setValue('quickMessages', updatedQuickMessages);
                        },
                      );
                    }
                  }}
                >
                  保存
                </Button>
              </Box>
            </Box>
          );
        }}
      />

      <QuickMessagesDeleteModal open={open} setOpen={setOpen} remove={onDelete} />
    </>
  );
};
