import React, { useEffect } from 'react';
import {
  Box,
  TextField,
  Modal,
  IconButton,
  CircularProgress,
  Button,
} from '@mui/material';
import { useGetCaptchaQuery } from 'services/login';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';

import { styles } from '../styles';

export const BackendCaptcha = ({
  open,
  handleClose,
  captcha,
  setCaptcha,
  setCaptchaId,
  isLoading,
  handleSubmit,
}) => {
  const { data, isLoading: isLoadingCaptcha, refetch } = useGetCaptchaQuery();

  useEffect(() => {
    if (data) {
      setCaptchaId(data?.data?.captcha_id);
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0,0,0, 0.7)',
        },
      }}
    >
      <Box height="100%" display="flex" alignItems="center">
        <Box
          width="calc(100% - 60px)"
          margin="0 auto"
          backgroundColor="#fff"
          padding="24px"
          position="relative"
          sx={{
            '@media screen and (min-width: 1024px)': {
              width: '400px',
            },
          }}
        >
          {isLoadingCaptcha ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 15,
                }}
              >
                <CloseIcon />
              </IconButton>
              <Box display="flex" justifyContent="center">
                请输入图形验证码
              </Box>

              <Box
                width="100%"
                marginTop="10px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  alt="captcha"
                  src={`data:image/png;base64, ${data.data.captcha_image}`}
                  width="200"
                />
                <IconButton onClick={refetch}>
                  <RefreshIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  marginTop: '15px',
                }}
              >
                <TextField
                  value={captcha}
                  onChange={(e) => setCaptcha(e.target.value)}
                  variant="standard"
                  type="text"
                  placeholder="请输入验证码"
                  sx={styles.textField}
                />
              </Box>
              <Button
                sx={{
                  ...styles.btn,
                  marginTop: '20px',
                }}
                disabled={isLoading}
                onClick={() => {
                  handleSubmit();
                  handleClose();
                }}
              >
                {!isLoading ? (
                  <>登录</>
                ) : (
                  <CircularProgress
                    sx={{
                      '& circle': {
                        stroke: '#fff',
                      },
                    }}
                  />
                )}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
