export const getFilteredAnswers = ({ answers, isLogin }) => {
  return answers
    .filter((answer) => answer.type === 33)
    .map(({ data }) => {
      const splittedData = data.split(' ');
      const lastElementOfSplit = splittedData[splittedData.length - 1];

      const isURLContainsDotAtTheEnd = lastElementOfSplit.slice(-1) === '.';

      const formattedURL = isURLContainsDotAtTheEnd
        ? `https://${lastElementOfSplit.slice(0, lastElementOfSplit.length - 1)}`
        : `https://${lastElementOfSplit}`;

      return {
        originalURL: formattedURL,
        pingURL: isLogin
          ? `${formattedURL}/OpenAPI/v1/index/ping`
          : `${formattedURL}/ping`,
      };
    });
};
