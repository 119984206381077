import { Box } from '@mui/material';
import React from 'react';

export const AnimationPreview = () => {
  return (
    <Box
      sx={{
        color: '#2F80ED',
        fontSize: 12,
      }}
    >
      GIF
    </Box>
  );
};
