import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import {
  useGetMessagesQuery,
  useGetChatsQuery,
  useSendTextMutation,
  api,
  useGetAnswersQuery,
} from 'services/api';

import { Loader } from 'components/loader/Loader';

import { isMobile } from 'components/app-layout/AppLayout';
import { UserProfilePopover } from 'components/user-profile-popover/UserProfilePopover';
import { useDispatch, useSelector } from 'react-redux';
import { MESSAGE_STATUSES } from 'pages/direct-message/constants';
import { getUser } from 'redux/auth/slice';
import { updateChatsToTop } from 'web-socket/utils/update-chats';
import { MessageActions } from './components/message-actions/MessageActions';
import { appRoutes } from '../../routes/appRoutes';
import { Messages } from './components/messages/Messages';
import { Header } from './Header';
import { convertTimeToLabel } from '../../utils';

export function isIOS() {
  return ['iPhone Simulator', 'iPhone'].includes(navigator.platform);
}

export const DirectMessage = () => {
  const dispatch = useDispatch();
  const [sendText] = useSendTextMutation();
  const messagesContainer = useRef(null);
  const messageActionsContainerRef = useRef(null);
  const [text, setText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [limit, setLimit] = useState(10);
  const [page] = useState(1);

  const chatId = isMobile ? id : searchParams.get('chatId');

  const navigate = useNavigate();

  const fetchMoreData = () => {
    const limitValue = isMobile ? 50 : 150;
    setLimit((prevState) => prevState + limitValue);
  };

  const { data, isLoading } = useGetMessagesQuery(
    { chatId, limit, page },
    {
      skip: !chatId,
    },
  );
  const { data: autoAnswers, isSuccess: isAutoAnswersSuccess } = useGetAnswersQuery(
    undefined,
    { refetchOnMountOrArgChange: true },
  );
  const { data: chatsData, isLoading: isChatsLoading } = useGetChatsQuery();

  const activeChatData = chatsData?.data?.list.find(
    ({ id: userId }) => Number(userId) === Number(chatId),
  );

  const user = useSelector(getUser);

  const opponentData = activeChatData?.opponent;

  if (!chatId) {
    return navigate(appRoutes.HOME_PAGE);
  }

  useEffect(() => {
    if (messagesContainer && messagesContainer.current) {
      messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight;
    }
  }, [messagesContainer]);

  const handleSend = (value) => {
    const temporaryMessageId = new Date().getTime();

    const temporaryMessage = {
      id: temporaryMessageId,
      chat_id: Number(chatId),
      sender_id: user?.id,
      sent_at: new Date().getTime() / 1000,
      text: value,
      status: MESSAGE_STATUSES.LOADING,
    };

    dispatch(
      api.util.updateQueryData(
        'getMessages',
        { chatId: String(chatId), page: 1, limit },
        (draft) => {
          draft.data.list.unshift(temporaryMessage);
        },
      ),
    );

    sendText({ chat_id: Number(chatId), text: value }).then(({ data: responseData }) => {
      const messageId = responseData?.data;

      const newMessage = {
        ...temporaryMessage,
        id: messageId,
      };

      dispatch(
        api.util.updateQueryData(
          'getMessages',
          { chatId: String(chatId), page: 1, limit },
          (draft) => {
            // eslint-disable-next-line no-param-reassign
            draft.data.list = draft.data.list.map((message) => {
              if (message.id === temporaryMessageId) {
                return {
                  ...message,
                  id: messageId,
                  status: MESSAGE_STATUSES.SENT,
                };
              }

              return message;
            });
          },
        ),
      );

      dispatch(
        api.util.updateQueryData('getChats', undefined, (draft) => {
          const chats = draft.data.list;

          const idxOfChat = chats.findIndex(
            (chat) => chat?.opponent?.id === Number(chatId),
          );

          if (idxOfChat !== -1) {
            // eslint-disable-next-line no-param-reassign
            draft.data.list = updateChatsToTop({
              chats,
              chatIdToUpdate: Number(chatId),
              fieldsToUpdate: {
                last_message: newMessage,
              },
            });
          }
        }),
      );
      messagesContainer.current.scrollTop = 0;
    });
    setText('');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="hidden"
      sx={{
        // eslint-disable-next-line no-nested-ternary
        height: isMobile
          ? isIOS()
            ? '-webkit-fill-available'
            : '100vh'
          : 'calc(100vh - 87px)',
      }}
    >
      {isLoading || isChatsLoading ? (
        <Loader />
      ) : (
        <>
          <Header
            avatar={`https://static.moonscap.com${opponentData?.avatar}`}
            name={opponentData?.name}
            lastSeen={
              opponentData?.last_seen === 'now'
                ? opponentData?.last_seen
                : convertTimeToLabel(Number(opponentData?.last_seen))
            }
            isTyping={activeChatData?.isTyping}
            onClick={(e) => {
              if (isMobile) {
                navigate(appRoutes.USER_PROFILE.replace(':id', chatId));
              } else {
                setAnchorEl(e.currentTarget);
              }
            }}
          />
          <Messages
            data={data?.data?.list || []}
            opponentData={opponentData}
            id={Number(chatId)}
            fetchMoreData={fetchMoreData}
            isLoading={isLoading}
            activeChatData={activeChatData}
            messagesContainerRef={messagesContainer}
            handleSend={handleSend}
            messageActionsContainerRef={messageActionsContainerRef}
          />

          <MessageActions
            chatId={Number(chatId)}
            limit={limit}
            messagesContainerRef={messagesContainer}
            handleSend={handleSend}
            text={text}
            setText={setText}
            messageActionsContainerRef={messageActionsContainerRef}
            isAutoAnswersSuccess={isAutoAnswersSuccess}
            autoAnswers={autoAnswers?.data}
          />
        </>
      )}
      <UserProfilePopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        id={chatId}
        nickname={opponentData?.name}
      />
    </Box>
  );
};
