import CryptoJS from 'crypto-js';

export const getKeyAndIv = () => ({
  key: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY),
  iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_IV),
});

export const encrypt = (text) => {
  const { key, iv } = getKeyAndIv();

  const encrypted = CryptoJS.AES.encrypt(text, key, { iv });

  return encrypted;
};

export const decrypt = (text) => {
  const { key, iv } = getKeyAndIv();

  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Base64.parse(text) },
    key,
    { iv },
  );

  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};
