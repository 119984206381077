/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import { Box, Modal, IconButton } from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';
import { ReactComponent as Back } from '../../assets/arrow-back.svg';

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const File = ({
  url,
  caption,
  captionColor = '#1B1A57',
  file_name,
  mime_type,
  file_size,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '70vh',
    outline: 'none',
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        sx={{
          cursor: 'pointer',
        }}
        onClick={handleOpen}
      >
        <Box
          marginRight="5px"
          sx={{
            '& svg path': {
              fill: captionColor,
            },
          }}
        >
          <SourceIcon />
        </Box>
        <Box color={captionColor}>
          <Box>
            {file_name}
            .
            {mime_type}
          </Box>
          <Box>{formatBytes(file_size)}</Box>
        </Box>
      </Box>
      {caption && (
        <Box
          sx={{
            fontSize: '12px',
            color: captionColor,
          }}
        >
          {caption}
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0,0,0, 0.7)',
          },
        }}
      >
        <>
          <IconButton
            sx={{
              backgroundColor: '#fff',
              position: 'absolute',
              top: 15,
              left: 7,
              zIndex: 999,
            }}
            onClick={handleClose}
          >
            <Back />
          </IconButton>
          <Box sx={style}>
            <iframe src={url} width="100%" height="100%" />
          </Box>
        </>
      </Modal>
    </>
  );
};
