import React from 'react';
import { Box } from '@mui/material';
import { File } from '../message-types/File';
import { Animation } from '../message-types/Animation';
import { ImageWithPreview } from '../message-types/ImageWithPreview';
import { Media } from '../message-types/Media';
import { VideoWithPreview } from '../message-types/VideoWithPreview';

import { OutgoingMessageSkeleton } from './OutgoingMessageSkeleton';

export const OutgoingMessage = ({
  avatar,
  lastSeen,
  sentAt,
  text = null,
  sticker = null,
  animation = null,
  media = null,
  photo = null,
  video = null,
  document = null,
  id,
  status = '',
  activeChatData,
  handleSend,
  ...rest
}) => {
  return (
    <OutgoingMessageSkeleton
      id={id}
      avatar={avatar}
      sentAt={sentAt}
      lastSeen={lastSeen}
      editedAt={rest.edited_at}
      status={status}
      activeChatData={activeChatData}
      handleSend={() => handleSend(text)}
    >
      {(text || sticker) && (
        <Box
          sx={{
            fontSize: 12,
            color: '#fff',
            whiteSpace: 'pre-line',
          }}
        >
          {text || sticker}
        </Box>
      )}
      {document && <File {...document} {...rest} captionColor="#fff" />}
      {photo && <ImageWithPreview {...photo} {...rest} captionColor="#fff" />}
      {animation && <Animation {...animation} {...rest} captionColor="#fff" />}
      {video && <VideoWithPreview {...video} {...rest} captionColor="#fff" />}
      {media && <Media {...media} {...rest} captionColor="#fff" />}
    </OutgoingMessageSkeleton>
  );
};
